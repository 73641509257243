import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  message: string | ReactElement;
  onConfirm: () => void;
  onCancel: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmButtonIcon?: ReactElement;
  cancelButtonIcon?: ReactElement;
  testId?: string;
  maxWidth?: Breakpoint;
  fullWidth?: boolean;
  showCancelIcon?: boolean;
  hideConfirmation?: boolean;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  message,
  onConfirm,
  onCancel,
  confirmButtonText,
  cancelButtonText,
  testId = "no-test-id",
  maxWidth,
  fullWidth,
  showCancelIcon = false,
  confirmButtonIcon,
  cancelButtonIcon,
  hideConfirmation = false,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Dialog
      dir={theme.direction}
      open={open}
      onClose={onCancel}
      data-test-id={testId}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle data-test-id="dialog-title" id="confirmation-dialog-title">
        {title}
      </DialogTitle>

      {showCancelIcon && (
        <IconButton
          data-test-id="dialog-close-icon"
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}

      <DialogContent>
        <DialogContentText
          data-test-id="dialog-message"
          id="confirmation-dialog-description"
        >
          {message}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          data-test-id="dialog-secondary-button"
          onClick={onCancel}
          color="secondary"
          startIcon={cancelButtonIcon}
        >
          {cancelButtonText || t("cancel")}
        </Button>
        {!hideConfirmation && (
          <Button
            data-test-id="dialog-primary-button"
            onClick={onConfirm}
            color="primary"
            startIcon={confirmButtonIcon}
          >
            {confirmButtonText || t("ok")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
