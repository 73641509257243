import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { verifyEmail } from "../api/AuthService";
import { api } from "../api/api";
import { usePageTitle } from "../common/TitleContext";
import { useErrorHandler } from "../common/hooks";
{
  // TODO: we will remove this page when we integrate with Taxbandits
}
const EmailValidationPage = () => {
  const { t } = useTranslation();
  const { handleServerError } = useErrorHandler();
  const navigate = useNavigate();

  const { token } = useParams<{ token: string }>();

  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  usePageTitle("Email Validation");

  useEffect(() => {
    if (token) {
      api(verifyEmail(token))
        .then((response) => {
          console.log("Validation successful:", response.data);
          setIsSuccess(true);
        })
        .catch((error) => {
          console.error("Validation failed:", error);
          handleServerError(error);
          setIsSuccess(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [token]);

  const handleGoToHome = () => {
    navigate("/");
  };

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", p: 2 }}>
        <CircularProgress />
        <Typography variant="body1">{t("loading_text")}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 500, mx: "auto", textAlign: "center", p: 2 }}>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Box sx={{ position: "relative", display: "inline-flex" }}>
          {isSuccess ? (
            <MarkEmailReadIcon color="success" sx={{ fontSize: 70 }} />
          ) : (
            <UnsubscribeIcon color="error" sx={{ fontSize: 70 }} />
          )}
        </Box>
        <Typography
          color={isSuccess ? "green" : "red"}
          variant="h5"
          sx={{ fontWeight: "bold" }}
        >
          {isSuccess
            ? t("email_confirmation_description")
            : t("error_somethingWentWrong")}
        </Typography>
        <Typography variant="body1">
          {isSuccess
            ? t("email_confirmation_description")
            : t("error_somethingWentWrong")}
        </Typography>
      </Stack>
      <Button
        color="primary"
        variant="contained"
        onClick={handleGoToHome}
        sx={{ mt: 2 }}
      >
        {t("home_claimButtonTitle")}
      </Button>
    </Box>
  );
};

export default EmailValidationPage;
