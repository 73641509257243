import React, { useState } from "react";
import { CountryCode } from "libphonenumber-js";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Grid,
  Box,
  Stack,
  Button,
} from "@mui/material";
import AddCardIcon from "@mui/icons-material/AddCard";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { listItemStyle } from "../common/constants";
import PaymentService from "../api/paymentService";
import { getPaymentMethodByType, setLatestPaymentMethod } from "../redux/slices/paymentMethodsSlice";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { formatNumber } from "libphonenumber-js";
import { useTranslation } from "react-i18next";
import StorageManager from "../services/storage";
import {
  removeSpacesAndHyphens,
  stripTextAfterHyphen,
} from "../common/helpers";
import { api } from "../api/api";
import { PaymentAccountDetails, PaymentType, PropertyClientType, getTitleByClientType } from "../types/paymentTypes";
import { usePageTitle } from "../common/TitleContext";
import { useErrorHandler } from "../common/hooks";
import { AxiosError } from "axios";

const VccCardReview: React.FC = () => {
  const { t } = useTranslation();
  const { handleServerError } = useErrorHandler();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const vccPaymentMethod = useSelector((state: RootState) => {
    return getPaymentMethodByType(state, PaymentType.VirtualCard);
  });

  const data = useSelector((state: RootState) => state.vcc.vccPaymentData) as any;
  const states = useSelector((state: RootState) => {
    return state.paymentMethods.states;
  });

  usePageTitle(vccPaymentMethod?.title || t("virtualCard_title"));

  const [isLoading, setLoading] = useState(false);

  const getStateName = (key: string) => {
    return states.find((state) => state.code === key)?.name;
  }

  const getTitleByType = (clientType: PropertyClientType) => {
    return getTitleByClientType(vccPaymentMethod, clientType);
  }

  if (!data) {
    return null;
  }

  const { name, phone, address, email } = data;

  const handlePayment = async () => {
    try {
      setLoading(true);
      // Processing the zip code
      const processedZip = removeSpacesAndHyphens(
        stripTextAfterHyphen(data.address.zip)
      );

      const nationalNumber = data.phone.replace(/\+1|\s/g, "");

      const trimmedData = {
        ...data,
        phone: nationalNumber,
        name: data.name.trim(),
        address: {
          ...data.address,
          zip: processedZip,
        },
      };

      const response = await api<PaymentAccountDetails>(
        PaymentService.createPayment(trimmedData, data.endpoint)
      );

      dispatch(setLatestPaymentMethod(response.data));
      StorageManager.removeFormData();
      navigate(`/payment/submit/${id}/payment-methods/VCC/created`);
    } catch (error: any) {
      handleServerError(error as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  const addressString = address ? Object.entries(address)
    .filter(([key]) => key !== "country_code")
    .map(([key, value]) =>
      key === "state" ? getStateName(value as string) : value
    )
    .join(", ")
    : t("notProvided");

  return (
    <Grid container direction="column">
      <List>
        <ListItem sx={listItemStyle}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText
            data-test-id="name-label"
            secondary={t("fullName")}
            primary={name || t("notProvided")}
          />
        </ListItem>

        <ListItem sx={listItemStyle}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
            </Grid>
            <Grid item xs>
              <Collapse
                data-test-id="address-label"
                in={true}
                timeout="auto"
                unmountOnExit
              >
                {addressString.split(", ").map((line, index) => (
                  <Box key={index}>
                    <ListItemText
                      data-test-id={"address-" + line}
                      primary={line}
                    />
                  </Box>
                ))}
                <ListItemText secondary={getTitleByType(PropertyClientType.Address) || t("virtualCard_summary_address")} />
              </Collapse>
            </Grid>
          </Grid>
        </ListItem>

        {phone && (
          <ListItem sx={listItemStyle}>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText
              data-test-id="phone-label"
              secondary={getTitleByType(PropertyClientType.Phone) || t("virtualCard_phone_phoneTextFieldTitle")}
              primary={formatNumber(phone, vccPaymentMethod?.country as CountryCode, "INTERNATIONAL")}
            />
          </ListItem>
        )}

        {email && (
          <ListItem sx={listItemStyle}>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText
              data-test-id="email-label"
              secondary={getTitleByType(PropertyClientType.Email) || t("email")}
              primary={email}
            />
          </ListItem>
        )}
      </List>


      <Grid item>
        <Stack spacing={2} justifyContent="center">
          <Button
            data-test-id="tc-edit-button"
            onClick={() => navigate(-2)}
            size="large"
            variant="text"
            sx={{ color: "gray" }}
            disabled={isLoading}
          >
            <span>{t("virtualCard_summary_editButton")}</span>
          </Button>

          <LoadingButton
            data-test-id="create-button"
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            onClick={handlePayment}
            loading={isLoading}
            loadingPosition="start"
            startIcon={<AddCardIcon />}
          >
            <span>{t("vcc_createMyCard")}</span>
          </LoadingButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default VccCardReview;
