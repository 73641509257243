import CancelIcon from "@mui/icons-material/Cancel";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  Skeleton,
  styled,
} from "@mui/material";
import React, { useState } from "react";

const ImageBox = styled(Box)({
  position: "relative",
  width: "100%",
  "&:hover": {
    "& .imageOverlay": {
      opacity: 1,
    },
  },
});

const ImageOverlay = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.4)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  opacity: 0,
  transition: "opacity 0.3s",
});

const ImageModal: React.FC<{ src: string }> = ({ src }) => {
  const [open, setOpen] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <ImageBox>
        {!imageLoaded && (
          <Skeleton variant="rectangular" width="100%" height={60} />
        )}
        <img
          data-test-id="image-preview"
          src={src}
          alt=""
          style={{
            objectFit: "cover",
            width: "100%",
            maxHeight: "60px",
            display: imageLoaded ? "block" : "none",
          }}
          onLoad={() => setImageLoaded(true)}
          onClick={handleOpen}
        />
        <ImageOverlay className="imageOverlay">
          <Button
            data-test-id="zoom-button"
            sx={{ width: "100%", height: "100%" }}
            onClick={handleOpen}
          >
            <ZoomInIcon fontSize="large" style={{ color: "#fff" }} />
          </Button>
        </ImageOverlay>
      </ImageBox>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          sx: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
        }}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Fade in={open}>
          <Box
            sx={{
              outline: "none",
              position: "relative",
            }}
          >
            <img
              data-test-id="original-image"
              src={src}
              alt=""
              style={{ maxHeight: "80vh", maxWidth: "80vw" }}
            />
            <Button
              data-test-id="close-button"
              size="medium"
              sx={{
                position: "absolute",
                padding: 1,
                m: 0,
                top: 0,
                left: 0,
                cursor: "pointer",
                color: "lightGray",
              }}
              onClick={handleClose}
            >
              <CancelIcon sx={{ height: "40px", width: "40px" }} />
            </Button>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ImageModal;
