import i18n from "i18next";

type LanguageVersions = {
  [key: string]: {
    pdf: string;
    content: string;
  };
};

const amazonLink = "https://nmible-terms-and-conditions.s3.eu-central-1.amazonaws.com";

export const getTermsAndConditionsLinks = () => {
  let language = i18n.language.toLowerCase();

  if (import.meta.env.VITE_CLIENT == "IQVIA") {
    let tandcLinks = JSON.parse(import.meta.env.VITE_TC_HTML_LINKS);
    let pdfLinks = JSON.parse(import.meta.env.VITE_TC_PDF_LINKS);

    return {
      pdf: pdfLinks[language],
      content: tandcLinks[language],
    }
  } else {
    // Since they are all the same no need to copy paste. If in the future we need something different we will just add an if/switch.
    return {
      pdf: `${amazonLink}/${language}.pdf`,
      content: `${amazonLink}/summary_tandcs_${language}.html`,
    }
  }
};
