import { Alert, Button, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Webcam from "react-webcam";
import { v4 as uuidv4 } from 'uuid';

const videoConstraints = {
    height: 600,
    facingMode: "user",
};

interface IOwnProps {
    onCapture: (file: File) => void;
    onCancel: () => void;
}

const CaptureImage: React.FC<IOwnProps> = ({ onCapture, onCancel }) => {
    const { t } = useTranslation();
    const webcamRef = useRef<any>(null);
    const [cameraError, setCameraError] = useState<boolean>(false)

    const capture = () => {
        const imageSrc = webcamRef.current.getScreenshot();

        fetch(imageSrc)
            .then(res => res.blob())
            .then(blob => {
                const file = new File([blob], uuidv4(), { type: "image/png" })

                onCapture(file);
            })
    }

    return (
        <>
            {!cameraError && (
                <div style={{ textAlign: "center" }}>
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                        onUserMediaError={() => { setCameraError(true) }}
                    />
                </div>
            )}

            {cameraError && (
                <div style={{ textAlign: "center" }}>
                    <Alert severity="error">{t("webcam_error")}</Alert>
                </div>
            )}

            <Button
                data-test-id="capture-screen-button"
                variant="contained"
                color="secondary"
                fullWidth
                size="large"
                sx={{ mt: 2 }}
                onClick={capture}
                disabled={cameraError}
            >
                {t("capture")}
            </Button>

            <Typography align="center">
                <Button
                    fullWidth
                    data-test-id="cancel-capture-button"
                    variant="text"
                    color="primary"
                    size="small"
                    sx={{ pl: 2, pr: 2, mt: 2 }}
                    onClick={onCancel}
                >
                    {t("cancel")}
                </Button>
            </Typography>
        </>
    );
};

export default CaptureImage;