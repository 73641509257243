import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { Alert, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { VehicleQuestion } from "../types/paymentTypes";

function VehicleDetailsPreview(questions: VehicleQuestion[]) {
  const { t } = useTranslation();

  return (
    <Alert
      icon={<DirectionsCarIcon color="action" />}
      data-test-id="payment-account"
      sx={{
        border: "1px solid #0000001F",
        textAlign: "left",
        background: "inherit",
      }}
    >
      <Typography
        data-test-id="vehicle-details-title"
        variant="subtitle2"
        color="text.secondary"
      >
        {t("vehicleDetails_title")}
      </Typography>
      <List>
        {questions.map((question) => (
          <ListItem key={question.property} sx={{ p: 0 }}>
            <ListItemText
              data-test-id="vehicle-question-answer"
              primary={question.summary}
              secondary={
                question.selected_option?.label || "No answer selected"
              }
            />
          </ListItem>
        ))}
      </List>

      <Link to={"/payment/submit/mileage/vehicle?fromPage=summary"}>
        {t("vehicleDetails_editButton")}
      </Link>
    </Alert>
  );
}

export default VehicleDetailsPreview;
