import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { updateNotificationSettings } from "../api/notificationService";

const EnableNotificationsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid
      spacing={0}
      direction="column"
      container
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12}>
        <NotificationAddIcon
          data-test-id="notification-icon"
          sx={{ fontSize: 200 }}
        />
      </Grid>

      <Grid item xs={12} sx={{ mb: 2 }}>
        <Typography
          align="center"
          variant="subtitle1"
          sx={{ fontWeight: "bold" }}
          data-test-id="confirmation-msg"
        >
          {t("claim_toastConfirmationMessage")}
        </Typography>

        <Typography
          variant="subtitle2"
          align="center"
          data-test-id="notify-me-msg"
        >
          {t("NotifyMe_description")}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ mb: 2 }}>
        <Button
          data-test-id="primary-button"
          size="large"
          variant="contained"
          color="primary"
          onClick={async () => {
            await updateNotificationSettings(true);
            navigate("/payments");
          }}
        >
          {t("NotifyMe_confirmButton")}
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Button
          data-test-id="secondary-button"
          size="large"
          variant="text"
          color="secondary"
          onClick={async () => {
            navigate("/payments");
          }}
        >
          {t("NotifyMe_skipButton")}
        </Button>
      </Grid>
    </Grid>
  );
};
export default EnableNotificationsPage;
