import { Box } from "@mui/material";
import ImageModal from "./ImageModal";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

function ReceiptImagePreview(props: { source?: string }) {
  const url =
    props.source ??
    useSelector((state: RootState) => state.claims.receiptClaim)
      ?.receipt_photo_signed_url;

  return (
    <Box
      sx={{
        width: "100px",
        height: "auto",
        overflow: "hidden",
        p: "20px 20px 0 0",
      }}
    >
      <ImageModal src={url ?? ""} />
    </Box>
  );
}

export default ReceiptImagePreview;
