import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ModalState = {
  open: boolean
  status: string
  message: string
};

const initialState: ModalState = {
  open: false,
  status: '',
  message: '',
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModalState: (state, action: PayloadAction<ModalState>) => {
      state.open = action.payload.open;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
  },
});

export const { setModalState } = modalSlice.actions;

export default modalSlice.reducer;