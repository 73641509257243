interface AppConfig {
  clientName: string;
  supportEmail?: string;
  supportTelephone?: string;
  showSupport: boolean;
  supportMethod: SupportMethod;
}

class ConfigService {
  config: AppConfig;

  constructor() {
    const env = import.meta.env;
    this.config = {
      clientName: env.VITE_CLIENT,
      supportEmail: env.VITE_SUPPORT_EMAIL,
      supportTelephone: env.VITE_SUPPORT_TELEPHONE,
      showSupport: !!(env.VITE_SUPPORT_EMAIL || env.VITE_SUPPORT_TELEPHONE),
      supportMethod: env.VITE_SUPPORT_EMAIL
        ? SupportMethod.email
        : env.VITE_SUPPORT_TELEPHONE
        ? SupportMethod.telephone
        : SupportMethod.none,
    };
  }

  supportContact(): string {
    return this.config.supportEmail ?? this.config.supportTelephone ?? "";
  }
}

export enum SupportMethod {
  email = "email",
  telephone = "telephone",
  none = "none",
}

export const configService = new ConfigService();
