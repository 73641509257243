import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { reset } from "../../redux/store";
import StorageManager from "../../services/storage";
import { configService } from "../../services/configService";

interface IOwnProps {
  onNext: () => void;
}

const ConfirmationStep: React.FC<IOwnProps> = ({ onNext }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const signout = () => {
    reset();
    StorageManager.clear();
    navigate("/login");
  };

  return (
    <>
      <Typography
        data-test-id="close-account-confirmation-title"
        variant="h4"
        component="h2"
      >
        {" "}
        {t("closeAccount_summary_title", {
          client: configService.config.clientName,
        })}{" "}
      </Typography>

      <Typography
        data-test-id="close-account-confirmation-description"
        variant="body2"
      >
        {t("closeAccount_summary_description")}
      </Typography>

      <Button
        data-test-id="close-account-confirmation-button"
        sx={{ textTransform: "initial", fontWeight: "bold", mt: 2 }}
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        onClick={signout}
      >
        {t("settings_logout")}
      </Button>
    </>
  );
};

export default ConfirmationStep;
