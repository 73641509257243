import { AxiosRequestConfig } from "axios";

export const getProfile = (): AxiosRequestConfig => {
  return {
    url: "/claimants/profile",
    method: "GET",
  };
};

export const updateProfile = (data: UpdateProfileData): AxiosRequestConfig => {
  return {
    url: "/claimants/profile",
    method: "PUT",
    data: data,
  };
};

export interface UpdateProfileData {
  name?: string;
  preferred_language?: string;
}

export const getCarers = (): AxiosRequestConfig => {
  return {
    url: "/participants/carers/",
    method: "GET",
  };
};

export const createCarer = (): AxiosRequestConfig => {
  return {
    url: "/participants/carers/",
    method: "POST",
  };
};
// this delete or lock the carer
export const disableCarer = (carerId: number): AxiosRequestConfig => {
  return {
    url: `participants/carers/${carerId}/`,
    method: "DELETE",
  };
};

export const unlockCarer = (carerId: number): AxiosRequestConfig => {
  return {
    url: `participants/carers/${carerId}/unlock`,
    method: "POST",
  };
};
