import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";

interface CardContainerProps {
  title?: string;
  subtitle?: string;
  topRightContent?: React.ReactNode;
  children: React.ReactNode;
}

const CardContainer: React.FC<CardContainerProps> = ({
  title,
  subtitle,
  topRightContent,
  children,
}) => {
  return (
    <Paper
      id="card_container"
      elevation={0}
      square={false}
      variant="outlined"
      sx={{
        mt: 2,
        mb: 2,
      }}
    >
      {(title || subtitle || topRightContent) && (
        <>
          <Grid
            data-test-id="container"
            container
            justifyContent="space-between"
            alignItems="center"
            marginBottom="20px"
          >
            <Grid item style={{ padding: "20px 20px 0" }}>
              {title && (
                <Typography
                  data-test-id="container-title"
                  variant="h6"
                  fontWeight="bold"
                >
                  {title}
                </Typography>
              )}
              {subtitle && (
                <Typography
                  data-test-id="container-subtitle"
                  variant="subtitle1"
                  color="gray"
                >
                  {subtitle}
                </Typography>
              )}
            </Grid>

            {topRightContent && (
              <Grid
                data-test-id="container-top-right-content"
                item
                sx={{ pl: "20px" }}
              >
                {topRightContent}
              </Grid>
            )}
          </Grid>
          <Divider />
        </>
      )}

      <Box sx={{ p: 2 }}>{children}</Box>
    </Paper>
  );
};

export default CardContainer;
