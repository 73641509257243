import { getTermsAndConditionsLinks } from "../common/TermsAndConditionsContentProvider";
import { useTranslation } from "react-i18next";

const TermsAndConditions = () => {
  const { t } = useTranslation();

  return (
    <iframe
      data-test-id="tc-iframe"
      src={getTermsAndConditionsLinks().content}
      title={t("terms_termsAndConditions")}
      width="100%"
      height="100%"
      style={{
        border: "none",
        overflow: "hidden",
      }}
    />
  );
};

export default TermsAndConditions;
