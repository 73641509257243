import { AxiosRequestConfig } from "axios";
import { PaymentAccountDetails, ReceiptClaimData } from "../types/paymentTypes";

export const getWaitingStipends = (): AxiosRequestConfig => {
  return {
    url: "/claimants/claims/waiting-stipends",
    method: "GET",
  };
};

export const submitReceiptClaim = (
  data: ReceiptClaimData
): AxiosRequestConfig => {
  return {
    url: "/claimants/claims/receipt/",
    method: "POST",
    data: data,
  };
};

export const approveStipend = (
  claimId: string,
  accountDetails: PaymentAccountDetails
): AxiosRequestConfig => {
  return {
    url: `/claimants/claims/stipends/${claimId}/approve`,
    method: "PUT",
    data: accountDetails,
  };
};

export const rejectStipends = (
  claimId: number,
  notes?: string
): AxiosRequestConfig => {
  return {
    url: `/claimants/claims/stipends/${claimId}/reject`,
    method: "PUT",
    data: { notes: notes },
  };
};

export const getClaims = (
  pageNumber: number = 1,
  pageSize: number = 10
): AxiosRequestConfig => {
  return {
    url: `/claimants/claims/?page_number=${pageNumber}&page_size=${pageSize}`,
    method: "GET",
  };
};

export const getClaimById = (claim_id: string): AxiosRequestConfig => {
  return {
    url: `/claimants/claims/${claim_id}`,
    method: "GET",
  };
};

export const getCarerClaims = (
  pageNumber: number = 1,
  pageSize: number = 10
): AxiosRequestConfig => {
  return {
    url: `/participants/carers/claims/?page_number=${pageNumber}&page_size=${pageSize}`,
    method: "GET",
  };
};
