import { AxiosRequestConfig } from "axios";

export const termsAndConditionsStatus = (): AxiosRequestConfig => {
  return {
    url: "/claimants/terms-and-conditions",
    method: "GET",
  };
};

export const acceptTermsAndConditions = (): AxiosRequestConfig => {
  return {
    url: "/claimants/terms-and-conditions/accept",
    method: "PUT",
    data: {},
  };
};
