import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import VccVisualise from "./VccVisualisePage";

function VccCardCreated() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <VccVisualise />
      <Grid sx={{ width: "100%", mb: "1rem" }}>
        <Typography
          data-test-id="vcc-created-title"
          variant="h6"
          component="h6"
          fontWeight="500"
          color="text.secondary"
          textAlign="left"
          pt="30px"
        >
          {t("virtualCard_success_headerDescription")}
        </Typography>
        <Typography
          data-test-id="vcc-created-description"
          variant="body2"
          textAlign="left"
        >
          {t("vcc_created_description")}
        </Typography>
      </Grid>

      <Button
        data-test-id="vcc-created-button"
        fullWidth
        variant="contained"
        size="large"
        startIcon={<CheckCircleIcon />}
        onClick={() => {
          navigate(`/payment/submit/${id}/summary`);
        }}
      >
        {t("ok")}
      </Button>
    </>
  );
}

export default VccCardCreated;
