import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useErrorHandler } from "../common/hooks";
import CardContainer from "../pages/CardContainer";
import { Carer } from "../types/common";
import InviteCarerButton from "./InviteCarerButton";

interface Props {
  onInvite: (carer: Carer) => void;
}

const EmptyCarersPage: React.FC<Props> = ({ onInvite }: Props) => {
  const { handleServerError } = useErrorHandler();
  const { t } = useTranslation();

  return (
    <CardContainer>
      <Box textAlign={"center"}>
        <Typography data-test-id="invite-carer-title" variant="h4" gutterBottom>
          {t("carerInvitation_inviteYourCarer")}
        </Typography>
        <Typography data-test-id="invite-carer-p1" variant="body1" gutterBottom>
          {t("carerInvitation_inviteCarerBody_p1")}
        </Typography>
        <Typography data-test-id="invite-carer-p2" variant="body1" gutterBottom>
          {t("carerInvitation_inviteCarerBody_p2")}
        </Typography>
        <Box
          data-test-id="invite-carer-image"
          component={"img"}
          sx={{ display: "block", m: 3, mx: "auto" }}
          src="/images/carer.svg"
        />

        <InviteCarerButton onError={handleServerError} onInvite={onInvite} />
      </Box>
    </CardContainer>
  );
};

export default EmptyCarersPage;
