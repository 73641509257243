import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getStudy } from "../api/AuthService";
import { useAPI } from "../api/api";
import { useErrorHandler } from "../common/hooks";
import { RootState } from "../redux/store";
import StorageManager from "../services/storage";
import { Study } from "../types/paymentTypes";
import CardContainer from "./CardContainer";

const ValueWithLabel = ({
  label,
  children,
}: {
  label: string;
  children: any;
}) => (
  <Grid item xs={12} sm={6} md={4}>
    <Box padding={1}>
      <Typography data-test-id={label} variant="body1" fontWeight={"bold"}>
        {label}
      </Typography>
      <Typography data-test-id={`${label}-value`} variant="body2">
        {children}
      </Typography>
    </Box>
  </Grid>
);

export default function TrialDetailsPage() {
  const { t } = useTranslation();
  const { handleServerError } = useErrorHandler();

  const profile = useSelector((state: RootState) => state.auth.profile);

  const { data, loading, error } = useAPI<Study>(getStudy());

  useEffect(() => {
    if (!error) {
      return;
    }

    handleServerError(error);
  }, [error]);

  const isCarer = StorageManager.getLoginInformation()?.userType === "carer";
  if (isCarer) return;

  if (loading) {
    return (
      <CardContainer>
        <Grid data-test-id="skeleton" container spacing={2}>
          {Array.from(new Array(9)).map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Skeleton variant="rectangular" height={60} />
            </Grid>
          ))}
        </Grid>
      </CardContainer>
    );
  }

  function getFullAddress(): string {
    if (!data) return "";
    const address2Part = data.address2 ? `, ${data.address2}` : "";
    return `${data.address1}${address2Part}, ${data.post_code}`;
  }

  return (
    <CardContainer title={data?.title} subtitle={t("visits_trialSubtitle")}>
      <Grid container spacing={2}>
        <ValueWithLabel label={t("auth_participantIdNumber")}>
          {profile?.participant_number}
        </ValueWithLabel>

        <ValueWithLabel label={t("trialDetails_studyNumber")}>
          {data?.protocol_number}
        </ValueWithLabel>
        <ValueWithLabel label={t("trialDetails_location")}>
          {getFullAddress()}
        </ValueWithLabel>
        <ValueWithLabel label={t("trialDetails_sponsor")}>
          {data?.sponsor}
        </ValueWithLabel>

        <ValueWithLabel label={t("fullName")}>{profile?.name}</ValueWithLabel>
        <ValueWithLabel label={t("email")}>{profile?.email}</ValueWithLabel>
        <ValueWithLabel label={t("trialDetails_description")}>
          {data?.description}
        </ValueWithLabel>
        <ValueWithLabel label={t("trialDetails_principalInvestigator")}>
          {data?.pi_name}
        </ValueWithLabel>
        <ValueWithLabel label={t("trialDetails_primaryContactName")}>
          {data?.primary_contact_name}
        </ValueWithLabel>
        <ValueWithLabel label={t("trialDetails_primaryContactPhone")}>
          {data?.primary_contact_phone}
        </ValueWithLabel>
        <ValueWithLabel label={t("trialDetails_primaryContactEmail")}>
          {data?.primary_contact_email}
        </ValueWithLabel>
      </Grid>
    </CardContainer>
  );
}
