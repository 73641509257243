import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { api } from "../api/api";
import paymentService from "../api/paymentService";
import { usePageTitle } from "../common/TitleContext";
import { getSymbol } from "../common/helpers";
import { useErrorHandler } from "../common/hooks";
import { useVisits } from "../common/useVisits";
import VehicleDetailsPreview from "../components/VehicleDetailsPreview";
import { clearMileageClaimData } from "../redux/slices/claimsSlice";
import { showToast } from "../redux/slices/toastSlice";
import { RootState } from "../redux/store";
import { Visit, dateString, isElectricVehicle } from "../types/common";
import {
  ConversionResponse,
  ConvertCurrencyData,
  MileageClaimData,
  VehicleQuestion,
} from "../types/paymentTypes";
import SummaryPageUI, {
  Action,
  DetailSection,
  VisitDetailSection,
} from "./SummaryPageUI";

import { INotificationEvent } from "../api/notificationService";

const MileageSummaryPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleServerError } = useErrorHandler();

  const [visit, setVisit] = useState<Visit | null>(null);
  const [isAccepting, setIsAccepting] = useState(false);
  const [conversionData, setConversionData] = useState<ConversionResponse | null>(null);

  usePageTitle(t("claim_chooseClaimMileage"));

  const notificationsEnabledByUser = useSelector((state: RootState) =>
    state.auth.notificationPreferences?.groups[0]?.events.some(
      (x: INotificationEvent) => x.is_allowed
    )
  );

  const claim: MileageClaimData | null = useSelector(
    (state: RootState) => state.claims.mileageClaim
  );

  const questions = useSelector(
    (state: RootState) => state.auth.profile?.distance_rule_screens ?? []
  );

  const profileCurrency = useSelector(
    (state: RootState) => state.auth.profile?.currency ?? "USD"
  );

  const distance_unit = useSelector(
    (state: RootState) => state.auth.profile?.distance_unit
  );

  const latestPaymentAccount = useSelector(
    (state: RootState) => state.paymentMethods.latestPaymentAccount
  );

  const paymentCurrencySymbol = getSymbol(
    latestPaymentAccount?.payment_currency ?? "USD"
  );

  const { visits, isLoading: isLoadingVisits } = useVisits();

  const handleCurrencyConversion = async () => {
    if (!latestPaymentAccount) return;
    if (!claim?.total_amount) return;
    if (profileCurrency === latestPaymentAccount?.payment_currency) return;

    const conversionInput: ConvertCurrencyData = {
      source: profileCurrency,
      to: latestPaymentAccount.payment_currency,
      amount: claim.total_amount,
      country_to_pay: latestPaymentAccount.payment_country_iso,
    };

    try {
      const response = await api(
        paymentService.convertCurrency(conversionInput)
      );
      setConversionData(response.data);
    } catch (error: any) {
      handleServerError(error);
    }
  };

  useEffect(() => {
    handleCurrencyConversion();
  }, [profileCurrency, latestPaymentAccount]);

  useEffect(() => {
    if (claim?.visit_id) {
      let visit = visits.find((item) => {
        return item.id === claim?.visit_id;
      });
      if (visit) setVisit(visit);
    }
  }, [claim, visits.length]);

  const submitClaim = () => {
    if (!latestPaymentAccount) return;

    if (isAccepting) return;

    setIsAccepting(true);

    const claimData = {
      ...claim,
      payment_details: latestPaymentAccount,
      payment_country_iso: latestPaymentAccount.payment_country_iso,
      conversion_uuid: conversionData?.conversion_uuid,
    };

    api(paymentService.submitMileageClaim(claimData))
      .then(() => {
        dispatch(showToast(t("claim_toastConfirmationMessage")));
        dispatch(clearMileageClaimData());

        if (
          "Notification" in window &&
          Notification.permission !== "granted" &&
          notificationsEnabledByUser
        ) {
          navigate("/enable-notifications");
        } else {
          navigate("/payments");
        }
      })
      .catch((error) => {
        console.error("Error sending your claim:", error);
        handleServerError(error);
      })
      .finally(() => {
        setIsAccepting(false);
      });
  };

  const handleDeclinePayment = () => {
    dispatch(clearMileageClaimData());
    navigate("/");
  };

  if (!claim) {
    return <CircularProgress />;
  }

  const actions: Action[] = [
    {
      label: t("cancel"),
      onClick: handleDeclinePayment,
      color: "secondary",
      variant: "outlined",
      loading: false,
    },
    {
      label: t("reviewClaimDetail_submitMyClaim"),
      onClick: submitClaim,
      color: "primary",
      variant: "contained",
      loading: isAccepting,
    },
  ];

  const isElectric = isElectricVehicle();

  const detailSections: DetailSection[] =
    claim.rateCalculationResults?.rates.flatMap((item) => {
      return [
        {
          title: distance_unit === "mi" ? t("miles") : t("kilometers"),
          content: `${item.distance} ${distance_unit}`,
        },
        {
          title:
            distance_unit === "mi"
              ? t("mileageReview_RatePerMile")
              : t("mileageReview_RatePerKilometer"),
          subtitle: isElectric
            ? t("vehicleSummary_IncludesElectricVehicle")
            : undefined,
          content: `${getSymbol(profileCurrency)}${item.rate?.toFixed(2)}`,
        },
      ];
    }) ?? [];

  if (conversionData) {
    let conversionInfo = [
      {
        title: t("claim_originalTotal"),
        content: `${getSymbol(conversionData.original_currency)}${
          conversionData.original_amount
        }`,
      },
      {
        title: t("claim_conversionRate"),
        content: `${getSymbol(conversionData.original_currency)}1 = ${getSymbol(
          conversionData.requested_currency
        )}${conversionData.conversion_rate}`,
      },
    ];
    detailSections.push(...conversionInfo);
  }

  const visitSection: VisitDetailSection = {
    title: t("claimDetail_relatedVisit"),
    content: visit?.name ?? t("loading_text"),
    date: visit ? dateString(visit) : t("loading_text"),
  };

  const totalSection: DetailSection = {
    title: t("mileageReview_Total"),
    content: `${paymentCurrencySymbol}${(
      conversionData?.amount_to_pay ?? claim.total_amount
    )?.toFixed(2)}`,
  };

  function vehicleDetails(
    questions: VehicleQuestion[]
  ): React.ReactNode | undefined {
    if (questions.length === 0) return undefined;
    return VehicleDetailsPreview(questions);
  }

  return (
    <SummaryPageUI
      title={t("claim_reviewYourClaim")}
      isLoading={isLoadingVisits}
      showChangePaymentMethodButton={true}
      subtitle={t("claim_reviewSubtitle")}
      detailSections={detailSections}
      visitSection={visitSection}
      actions={actions}
      totalSection={totalSection}
      latestPaymentAccount={latestPaymentAccount}
      bottomContent={vehicleDetails(questions)}
    />
  );
};

export default MileageSummaryPage;
