import { DistanceCalculationResult } from "../api/paymentService";
import { PaymentStatus } from "./stipendTypes";

export interface ConvertCurrencyData {
  source: string;
  to: string;
  amount: number;
  country_to_pay: string;
}
export interface ConversionResponse {
  conversion_uuid: string;
  original_amount: number;
  amount_to_pay: number;
  original_currency: string;
  requested_currency: string;
  payment_country_iso: string;
  conversion_rate: string;
}
export interface PaymentMethodsResponse {
  has_failed_payments: boolean;
  country: string;
  latest_payment_account?: PaymentAccountDetails | null;
  available_payment_methods: AvailablePaymentMethod[];
}

export interface PaymentAccountDetails {
  target_account_id: string;
  target_account_type: string;
  target_account_title: string | null;
  target_account_identifier: string;
  payment_currency: string;
  payment_country_iso: string;
}

export interface AvailablePaymentMethod {
  id?: number;
  title: string;
  type: string;
  properties: PaymentMethodProp[];
  required: string[];
  endpoint: string;
  payment_type: string;
  payment_country: string;
  country: string;
  currency: string;
}

export interface PaymentData {
  name: string;
  payment_country_iso: string;
}

export interface BankAccountData extends PaymentData {
  account: string;
  routing: string;
  type: string;
}

export interface DebitCardData extends PaymentData {
  card_number: string;
  expiration_date: string;
}

export interface ChequePaymentData extends PaymentData {
  email: string;
}

export interface VccPaymentData extends PaymentData {
  phone?: string;
  address?: any;
  state?: string;
}

export interface PaymentMethodProp {
  enum?: { key: string; value: string }[];
  options?: any;
  property_name: string;
  title: string;
  description?: string;
  client_type?: string;
  minLength: number;
  maxLength: number;
  pattern?: string;
  properties?: Record<string, PaymentMethodProp>;
  nestedProps?: PaymentMethodProp[]; // Used to remap the "properties"
  type: string;
}

export type VccData = {
  phone?: string;
  name: string;
  payment_country_iso: any;
  address?: {
    line_1?: string;
    city?: string;
    zip?: string;
    country_code?: string;
    state?: string;
  };
};

export interface VirtualCardResponse {
  vccs: VirtualCard[];
}

export interface VirtualCard {
  target_account_id: string;
  card_number: string;
  balance: number;
  currency: string;
  country_iso: string;
}

export interface ClaimsResponse {
  claims: Claim[];
}

export type ClaimItem = MileageItem | ReceiptItem | StipendItem;

export interface Claim {
  id: number;
  state: PaymentStatus;
  pre_approved: PaymentStatus;
  notes: string | null;
  claimant_id: number;
  created_at: string;
  last_modified_at: string | null;
  approver_payment_status_changed_at: string | null;
  claimant_payment_status_changed_at: string | null;
  reviewed_by: number | null;
  claim_owner: string;
  claim_type: "Receipt" | "Stipend" | "Mileage";
  payment_details: PaymentAccountDetails;
  items: ClaimItem[];
  visit_id: number;
  receipt_photo_url?: string;
  original_currency?: string;
  requested_currency?: string;
  conversion_rate?: number;
}

export interface MileageItem {
  amount: number;
  amount_to_pay?: number;
  original_distance_unit: String;
  distance: number;
  rate: number;
}

export interface ReceiptItem {
  amount: number;
  amount_to_pay?: number;
  category: string;
}

export interface StipendItem {
  amount: number;
  amount_to_pay?: number;
  claim_id: number;
  visit_id: number;
  rate_id: number;
  id: number;
  withholding_tax_percentage: number;
  total_amount: number;
}

export interface StatesList {
  values: StateItem[];
}

export interface StateItem {
  code: string;
  name: string;
}

export interface ReceiptClaimData {
  receipt_photo_url?: string;
  receipt_photo_signed_url?: string;
  visit_id?: number;
  items?: CategoryAmount[];
  total_amount?: number;
  payment_country_iso?: string;
  currency?: string;
}

export interface MileageClaimData {
  total_amount?: number;
  visit_id?: number;
  items?: { distance: number; original_distance_unit: string; rate: number }[];
  payment_country_iso?: string;
  rateCalculationResults?: DistanceCalculationResult;
}

export interface MileageClaimItem {
  amount: number;
  amount_to_pay?: number;
  originalDistanceUnit: String;
  distance: number;
  rate: number;
}

export interface CategoryAmount {
  category: string;
  value: string;
  amount?: number;
}

export interface UploadResponse {
  receipt_photo_url: string;
  receipt_photo_signed_url: string;
}

export interface Study {
  id: number;
  title: string;
  protocol_number: string;
  description: string;
  address1: string;
  address2: string;
  post_code: string;
  sponsor: string;
  pi_name: string;
  primary_contact_name: string;
  primary_contact_phone: string;
  primary_contact_email: string;
}

export interface VehicleQuestion {
  title: string;
  description: string;
  summary: string;
  property: string;
  available_options: Answer[];
  selected_option?: Answer;
}

export interface Answer {
  label: string;
  value: Value;
}

export type Value = boolean | number | string;

export enum PaymentType {
  BankAccount = "BANK_ACCOUNT",
  DebitCard = "DEBIT_CARD",
  VirtualCard = "VCC",
  Check = "CHECK",
  Cash = "Cash pick up",
}

export enum PropertyClientType {
  MonthYear = "month-year",
  Number = "numeric",
  CardNumber = "card_number",
  Phone = "phone",
  Email = "email",
  Iban = "iban",
  Date = "date",
  Country = "country",
  State = "state",
  Address = "address",
  City = "city",
  ZIP = "zip",
  Line1 = "line_1",
}

export const remapPaymentMethods = (profileData: any) => {
  // TESTING DATA UNTIL BACK END IS READY - COMMENT OUT BEFORE COMMIT
  // const addressProperty = profileData.data.available_payment_methods
  //   .find((x: any) => x.payment_type == "VCC").properties.find((x: any) => x.property_name == "address");

  // addressProperty.client_type = "address";

  // Object.keys(addressProperty.properties).forEach(x => {
  //   if (x == "line_1") {
  //     addressProperty.properties[x].client_type = "line_1";
  //   }

  //   if (x == "city") {
  //     addressProperty.properties[x].client_type = "city";
  //   }

  //   if (x == "state") {
  //     addressProperty.properties[x].client_type = "state";
  //   }

  //   if (x == "zip") {
  //     addressProperty.properties[x].client_type = "zip";
  //   }
  // })

  // const newProp = JSON.parse(JSON.stringify(addressProperty));

  // newProp.client_type = "city";
  // newProp.title = "Second Nested";
  // newProp.property_name = "second_nested";

  // profileData.data.available_payment_methods
  //   .find((x: any) => x.payment_type == "VCC").properties.push(newProp)

  // TESTING DATA END----------------------------------------------------

  // Remap the nested props to look like the parent props
  profileData.data.available_payment_methods.forEach((paymentMethod: any) => {
    paymentMethod.properties.forEach((parentProperty: any) => {
      if (parentProperty.type == "object") {
        parentProperty.nestedProps = [];

        Object.keys(parentProperty.properties || {}).map(
          (nestedPropKey: string) => {
            parentProperty.nestedProps?.push({
              ...parentProperty?.properties![nestedPropKey],
              property_name: nestedPropKey,
            });
          }
        );
      }
    });
  });
};

export const getTitleByClientType = (
  paymentMethod: AvailablePaymentMethod | undefined,
  clientType: PropertyClientType
) => {
  return paymentMethod?.properties?.find((x) => x.client_type == clientType)
    ?.title;
};
