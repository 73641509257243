import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type OwnState = {
  isOpen: boolean;
  isForced?: boolean;
};

const initialState: OwnState = {
  isOpen: false,
  isForced: false,
};

const languageSelectorDialogSlice = createSlice({
  name: "languageSelectorDialog",
  initialState,
  reducers: {
    setIsLanguageSelectorDialogOpen: (
      state,
      action: PayloadAction<OwnState>
    ) => {
      state.isOpen = action.payload.isOpen;
      state.isForced = !!action.payload.isForced;
    },
  },
});

export const { setIsLanguageSelectorDialogOpen } =
  languageSelectorDialogSlice.actions;

export default languageSelectorDialogSlice.reducer;
