export interface InputFieldValidator {
  isValid: () => boolean;
}

export interface ValidationResponse {
  status: ValidationStatus;
  errorMessage: string;
}

export enum ValidationStatus {
  VALID = "VALID",
  NOT_VALID = "NOT_VALID",
  IN_PROGRESS = "IN_PROGRESS",
}

export const emailValidationCache: Record<string, any> = {};
export const phoneValidationCache: Record<string, any> = {};

export interface CustomFieldProps {
  value: string;
  labelText: string;
  onChange: (value: string, status: ValidationStatus) => void;
  required?: boolean;
}
