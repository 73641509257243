// TitleContext.tsx
import React, { createContext, useState, useContext, ReactNode } from "react";
import { useEffect } from "react";

interface TitleContextType {
  title: string;
  setTitle: (title: string) => void;
}

// Providing initial default value
const TitleContext = createContext<TitleContextType>({
  title: "",
  setTitle: () => {},
});

export const useTitle = () => useContext(TitleContext);

interface TitleProviderProps {
  children: ReactNode;
}

export const TitleProvider: React.FC<TitleProviderProps> = ({ children }) => {
  const [title, setTitle] = useState("");

  return (
    <TitleContext.Provider value={{ title, setTitle }}>
      {children}
    </TitleContext.Provider>
  );
};

export const usePageTitle = (title: string) => {
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle(title);

    // Cleanup function to reset the title when the component unmounts
    return () => setTitle("");
  }, [title, setTitle]);
};
