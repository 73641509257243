// Token Slice in Redux
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { Profile } from "../../types/common";
import { INotificatonPreferences } from "../../api/notificationService";
import {
  AvailablePaymentMethod,
  VehicleQuestion,
} from "../../types/paymentTypes";
import { RootState } from "../store";
import StorageManager from "../../services/storage";

interface AuthState {
  profile?: Profile;
  notificationPreferences?: INotificatonPreferences;
}

const initialState: AuthState = {
  profile: undefined,
  notificationPreferences: undefined,
};

export const RESET_STATE = "RESET_STATE";

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setProfile: (state, action) => {
      // I don't really love this. BUT this way we enable English everywhere with just this one line.
      action.payload.profile.available_languages.push("en-gb");

      // Assign an ID if not present
      action.payload.profile.available_payment_methods =
        action.payload.profile.available_payment_methods.map(
          (method: AvailablePaymentMethod, index: number) => ({
            ...method,
            id: method.id ?? index,
          })
        );

      state.profile = action.payload.profile;
    },
    setNotificationPreferences: (state, action) => {
      state.notificationPreferences = action.payload;
    },
    updateVehicleDetails: (state, action: PayloadAction<VehicleQuestion[]>) => {
      if (state.profile) {
        const updatedQuestions = action.payload.map((question) => ({
          ...question,
          selected_option: question.selected_option
            ? { ...question.selected_option }
            : undefined,
        }));
        state.profile.distance_rule_screens = updatedQuestions;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return initialState;
    });
  },
});

const selectAuthState = (state: RootState) => state.auth;

export const selectIsAuthenticated = createSelector(
  [selectAuthState],
  (auth) => {
    const token = StorageManager.getTokenData();

    if (!token) {
      return false;
    }

    const currentTime = Date.now() / 1000;

    return token.access_token && token.expires && token.expires > currentTime;
  }
);

export const selectAvailablePaymentMethods = createSelector(
  [selectAuthState],
  (auth) => (auth.profile ? auth.profile.available_payment_methods : [])
);

export const selectCurrency = createSelector([selectAuthState], (auth) =>
  auth.profile ? auth.profile.currency : "USD"
);

export const { updateVehicleDetails, setProfile, setNotificationPreferences } =
  authSlice.actions;
export default authSlice.reducer;
