import AddIcon from "@mui/icons-material/Add";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const StyledMenu = styled((props: MenuProps & { width: number }) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme, width }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: width,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

interface SubmitClaimFABProps {
  onSubmitMileage: () => void;
  onSubmitReceipt: () => void;
}

function SubmitClaimButton({
  onSubmitMileage,
  onSubmitReceipt,
}: SubmitClaimFABProps) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [buttonWidth, setButtonWidth] = useState<number>(0);
  const buttonRef = useRef<HTMLButtonElement>(null); // Ref for the button
  const available_claim_types: string[] =
    useSelector((state: RootState) => state.auth.profile)
      ?.available_claim_types ?? [];

  if (available_claim_types.length === 0) return;

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMileage = () => {
    handleClose();
    onSubmitMileage();
  };

  const handleReceipt = () => {
    handleClose();
    onSubmitReceipt();
  };

  function submitState(): {
    showMileage: boolean;
    showReceipt: boolean;
  } {
    const showMileage = available_claim_types.includes("Mileage");
    const showReceipt = available_claim_types.includes("Receipt");

    return { showMileage, showReceipt };
  }

  const { showMileage, showReceipt } = submitState();
  if (showMileage && showReceipt)
    return (
      <div>
        <Button
          data-test-id="submit-claim-button"
          id="customized-button"
          ref={buttonRef}
          aria-controls={open ? "customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          size="large"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          startIcon={<AddIcon />}
        >
          {t("claim_SubmitAnExpense")}
        </Button>
        <StyledMenu
          id="customized-menu"
          width={buttonWidth}
          MenuListProps={{
            "aria-labelledby": "customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem data-test-id="choose-claim-type" disabled disableRipple>
            {t("claim_chooseClaimTypeTitle")}
          </MenuItem>

          {showReceipt && (
            <MenuItem
              data-test-id="receipt-claim-type"
              onClick={handleReceipt}
              disableRipple
            >
              <ReceiptIcon sx={{ marginRight: 1 }} />{" "}
              {t("claim_chooseClaimReceipt")}
            </MenuItem>
          )}

          {showMileage && (
            <MenuItem
              data-test-id="mileage-claim-type"
              onClick={handleMileage}
              disableRipple
            >
              <DriveEtaIcon sx={{ marginRight: 1 }} />{" "}
              {t("claim_chooseClaimMileage")}
            </MenuItem>
          )}
        </StyledMenu>
      </div>
    );
  if (showReceipt)
    return (
      <Button
        data-test-id="submit-receipt-claim-button"
        id="customized-button"
        variant="contained"
        size="large"
        disableElevation
        onClick={handleReceipt}
        startIcon={<AddIcon />}
      >
        {t("claim_chooseClaimReceipt")}
      </Button>
    );
  if (showMileage)
    return (
      <Button
        data-test-id="submit-mileage-claim-button"
        id="customized-button"
        ref={buttonRef}
        aria-controls={open ? "customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        size="large"
        disableElevation
        onClick={handleMileage}
        startIcon={<AddIcon />}
      >
        {t("claim_chooseClaimMileage")}
      </Button>
    );
}

export default SubmitClaimButton;
