import { Box, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAPI } from "../api/api";
import { getCarers } from "../api/userService";
import { usePageTitle } from "../common/TitleContext";
import { useErrorHandler } from "../common/hooks";
import CarerItem from "../components/CarerItem";
import EmptyCarersPage from "../components/EmptyCarersPage";
import InviteCarerButton from "../components/InviteCarerButton";
import { Carer, CarersResponse } from "../types/common";


const CarersPage: React.FC = () => {
  const { handleServerError } = useErrorHandler();
  const { t } = useTranslation();
  usePageTitle(t("settings_carers"));

  const { data, setData, loading, error } = useAPI<CarersResponse>(getCarers());
  const [expanded, setExpanded] = useState<string | false>(false);

  useEffect(() => {
    if (!error) {
      return;
    }

    handleServerError(error);
  }, [error]);

  const handleAccordionChange =
    (code: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? code : false);
    };

  function inviteCarerHandler(carer: Carer) {
    const joinedData = [...(data?.carers ?? [])];
    joinedData.unshift(carer);

    setData({ carers: joinedData });
    setExpanded(carer.code);
  }

  function removeCarer(carer: Carer) {
    setData((prevData) => ({
      carers: (prevData?.carers ?? []).filter((c) => c.code !== carer.code),
    }));
  }

  function lockCarer(carer: Carer) {
    setData((prevData) => ({
      carers: (prevData?.carers ?? []).map((c) =>
        c.code === carer.code ? { ...c, is_locked: true } : c
      ),
    }));
  }

  function unlockCarer(carer: Carer) {
    setData((prevData) => ({
      carers: (prevData?.carers ?? []).map((c) =>
        c.code === carer.code ? { ...c, is_locked: false } : c
      ),
    }));
  }

  if (loading) {
    return (
      <Box data-test-id="carers-skeleton">
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Skeleton variant="rectangular" width={200} height={40} />
        </Box>
        <Box>
          <Skeleton variant="rectangular" height={60} sx={{ mb: 1 }} />
          <Skeleton variant="rectangular" height={60} sx={{ mb: 1 }} />
          <Skeleton variant="rectangular" height={60} sx={{ mb: 1 }} />
        </Box>
      </Box>
    );
  }

  if (!data || data.carers.length === 0) {
    return <EmptyCarersPage onInvite={inviteCarerHandler} />;
  }

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <InviteCarerButton
          onError={handleServerError}
          onInvite={inviteCarerHandler}
        />
      </Box>

      {data.carers.map((carer) => (
        <CarerItem
          key={carer.code}
          carer={carer}
          expanded={expanded === carer.code}
          onUIChange={handleAccordionChange(carer.code)}
          onDelete={(carer) => removeCarer(carer)}
          onLock={(carer) => lockCarer(carer)}
          onUnlock={(carer) => unlockCarer(carer)}
        />
      ))}
    </Box>
  );
};

export default CarersPage;
