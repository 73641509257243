import {
  CheckCircle,
  CreditCardOff,
  Email,
  Home,
  LocalPhone,
  Security,
  SyncDisabled,
  Timer,
} from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { usePageTitle } from "../common/TitleContext";
import {
  getPaymentMethodByType,
  getPaymentMethodIdByType,
} from "../redux/slices/paymentMethodsSlice";
import { RootState } from "../redux/store";
import { PaymentType } from "../types/paymentTypes";
import IconList from "./IconList";

const VccCardSetup: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const vccPaymentMethod = useSelector((state: RootState) => {
    return getPaymentMethodByType(state, PaymentType.VirtualCard);
  });

  const vccId = useSelector((state: RootState) => {
    return getPaymentMethodIdByType(state, PaymentType.VirtualCard);
  });

  usePageTitle(vccPaymentMethod?.title || t("virtualCard_title"));

  const renderElements = [
    { icon: <CreditCardOff />, text: t("virtualCard_skipPersonalInfoHelp_p4") },
    { icon: <Timer />, text: t("virtualCard_noteDescription_p2") },
    { icon: <SyncDisabled />, text: t("vcc_setup_no_transfer") },
    { icon: <Home />, text: t("vcc_setup_billing_address") },
    { icon: <LocalPhone />, text: t("virtualCard_personalInfoHelp_p2") },
    { icon: <Email />, text: t("vcc_setup_email") },
    { icon: <Security />, text: t("vcc_setup_secure_info") },
  ];

  const handleContinueButton = async () => {
    if (vccPaymentMethod) {
      navigate(`/payment/submit/${id}/payment-methods/${vccId}`, {
        state: { selectedMethod: vccPaymentMethod },
      });
    }
  };

  return (
    <Box>
      <Typography
        data-test-id="vcc-setup-title"
        sx={{
          fontSize: "14px",
          color: "text.secondary",
          pb: "20px",
          pt: "4px",
          fontWeight: "500",
        }}
      >
        {t("vcc_setup_title")}
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          pb: "20px",
        }}
      >
        <IconList items={renderElements} />
      </Box>
      <Button
        data-test-id="vcc-setup-button"
        fullWidth
        variant="contained"
        size="large"
        startIcon={<CheckCircle />}
        onClick={handleContinueButton}
      >
        {t("ok")}
      </Button>
    </Box>
  );
};

export default VccCardSetup;
