import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { api } from "../api/api";
import paymentService from "../api/paymentService";
import { useErrorHandler } from "../common/hooks";
import { updateVehicleDetails } from "../redux/slices/authSlice";
import { RootState } from "../redux/store";
import { VehicleQuestion } from "../types/paymentTypes";
import CardContainer from "./CardContainer";
import VehicleSummaryPage from "./VehicleSummaryPage";
import { calculateMileageRate } from "../services/payment";
import { AxiosError } from "axios";

const VehicleQuestionsPage: React.FC = () => {
  const { t } = useTranslation();
  const { handleServerError } = useErrorHandler();

  const isFromSummaryPage =
    new URLSearchParams(location.search).get("fromPage") == "summary";

  const previouslyInputedDistance = useSelector((state: RootState) => {
    if (state.claims.mileageClaim?.items?.length) {
      return state.claims.mileageClaim?.items[0]?.distance;
    }
  });

  const distance_unit = useSelector(
    (state: RootState) => state.auth.profile
  )!.distance_unit;

  const questions =
    useSelector(
      (state: RootState) => state.auth.profile?.distance_rule_screens
    ) ?? [];

  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<VehicleQuestion[]>([]);

  useEffect(() => {
    setSelectedOptions(questions);
  }, []);

  useEffect(() => {
    setSelectedOptions(questions);
    setActiveStep(0);
  }, [questions])

  const handleNext = (e: any) => {
    e.preventDefault();

    if (activeStep === questions.length - 1) {
      handleSubmit();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const submit = () => {
    setIsLoading(true);

    const data: any = {};
    const updatedQuestions = [...selectedOptions];

    updatedQuestions.forEach((item) => {
      data[item.property] = item.selected_option?.value;
    });

    api(paymentService.submitVehicleInfo(data))
      .then(async () => {
        updateLocalStorage(updatedQuestions);
        setIsSummaryOpen(false);

        if (isFromSummaryPage && previouslyInputedDistance) {
          try {
            const { isThresholdExceeded } = await calculateMileageRate(
              previouslyInputedDistance,
              distance_unit
            );

            if (isThresholdExceeded) {
              navigate("/payment/submit/mileage/threshold");
            } else {
              navigate("/payment/submit/mileage/summary");
            }
          } catch (error) {
            handleServerError(error as AxiosError);
          }
        } else {
          navigate("/payment/submit/mileage/visit");
        }
      })
      .catch((error) => {
        handleServerError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const dispatch = useDispatch();

  function updateLocalStorage(vehicleDetails: VehicleQuestion[]) {
    dispatch(updateVehicleDetails(vehicleDetails));
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleOptionChange = (value: string, questionIndex: number) => {
    const updatedSelectedOptions = selectedOptions.map((question, index) => {
      if (index === questionIndex) {
        return {
          ...question,
          selected_option: questions[questionIndex].available_options.find(
            (option) => option.label === value
          ),
        };
      }
      return question;
    });
    setSelectedOptions(updatedSelectedOptions);
  };

  const navigate = useNavigate();

  const handleSubmit = () => {
    setIsSummaryOpen(true);
  };

  return (
    <CardContainer
      title={t("vehicleDetails_title")}
      subtitle={
        t("mileageOnboarding_description_p1") +
        t("mileageOnboarding_description_p2")
      }
    >
      <Box sx={{ maxWidth: 400 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {questions.map((question, index) => (
            <Step data-test-id={`step-${index}`} key={question.property}>
              <StepLabel data-test-id={`step-title-${index}`}>
                {question.title}
              </StepLabel>
              <StepContent>
                <form>
                  <FormControl component="fieldset" sx={{ mb: 2 }}>
                    <FormLabel
                      data-test-id={`step-description-${index}`}
                      component="legend"
                    >
                      {question.description}
                    </FormLabel>
                    <RadioGroup
                      data-test-id={`step-radio-${index}`}
                      name={`options-${question.property}`}
                      value={
                        selectedOptions[index]?.selected_option?.label || ""
                      }
                      onChange={(event) =>
                        handleOptionChange(event.target.value, index)
                      }
                    >
                      {question.available_options.map((option) => (
                        <FormControlLabel
                          data-test-id="option-item"
                          key={option.label}
                          value={option.label}
                          control={<Radio data-test-id="radio" />}
                          label={option.label}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        data-test-id="next-button"
                        variant="contained"
                        type="submit"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {t("continue_text")}
                        {/* {index === questions.length - 1 ? "Finish" : "Next"} */}
                      </Button>

                      <Button
                        data-test-id="back-button"
                        disabled={index === 0}
                        type="reset"
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {t("back_button")}
                      </Button>
                    </div>
                  </Box>
                </form>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === questions.length && (
          <Typography data-test-id="steps-completed" sx={{ mt: 2, mb: 1 }}>
            {t("all_steps_completed")}
          </Typography>
        )}
      </Box>

      <VehicleSummaryPage
        questions={selectedOptions}
        isOpen={isSummaryOpen}
        isLoading={isLoading}
        onClose={() => setIsSummaryOpen(false)}
        onContinue={submit}
      />
    </CardContainer>
  );
};

export default VehicleQuestionsPage;
