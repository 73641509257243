import { useTranslation } from "react-i18next";
import CardContainer from "./CardContainer";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import InfoStep from "../components/CancelAccountSteps/InfoStep";
import VerifyPINStep from "../components/CancelAccountSteps/VerifyPINStep";
import NotesStep from "../components/CancelAccountSteps/NotesStep";
import ConfirmationStep from "../components/CancelAccountSteps/ConfirmationStep";

enum CancelAccountStep {
  PIN = "pin",
  Process = "process",
  Notes = "notes",
  Confirmation = "confirmation"
}

const nextStepMap = {
  [CancelAccountStep.PIN]: CancelAccountStep.Process,
  [CancelAccountStep.Process]: CancelAccountStep.Notes,
  [CancelAccountStep.Notes]: CancelAccountStep.Confirmation,
  [CancelAccountStep.Confirmation]: CancelAccountStep.Confirmation,
}

const CloseAccountPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  let { step = CancelAccountStep.PIN } = useParams<{ step: CancelAccountStep }>();

  const handleOnNextStep = () => {
    navigate(`/settings/submit/close-account/${nextStepMap[step].toString()}`);
  }

  return (
    <Grid>
      <CardContainer title={t("closeAccount_title")}>
        {step == CancelAccountStep.PIN && (<VerifyPINStep onNext={handleOnNextStep} />)}
        {step == CancelAccountStep.Process && (<InfoStep onNext={handleOnNextStep} />)}
        {step == CancelAccountStep.Notes && (<NotesStep onNext={handleOnNextStep} />)}
        {step == CancelAccountStep.Confirmation && (<ConfirmationStep onNext={handleOnNextStep} />)}
      </CardContainer>
    </Grid>
  );
};

export default CloseAccountPage;
