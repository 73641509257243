import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setReceiptClaimCategories } from "../redux/slices/claimsSlice";
import { selectSelectedVisit } from "../redux/slices/visitsSlice";
import CardContainer from "./CardContainer";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { usePageTitle } from "../common/TitleContext";
import ReceiptImagePreview from "../components/ReceiptImagePreview";
import { RootState } from "../redux/store"; ``
import { KeyValue } from "../types/common";
import { CategoryAmount } from "../types/paymentTypes";

function ReceiptCategoriesPage() {
  const { t } = useTranslation();
  usePageTitle(t("claim_chooseClaimReceipt"));

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const selectedVisit = useSelector((state: RootState) =>
    selectSelectedVisit(state)
  );
  const initialSelectedCategories = useSelector(
    (state: RootState) => state.claims.receiptClaim?.items ?? []
  );

  useEffect(() => {
    setSelectedCategories(
      initialSelectedCategories.map((item) => item.category)
    );
  }, []);

  const categories: CategoryAmount[] =
    selectedVisit?.categories.map((item: KeyValue) => {
      return {
        category: item.key,
        value: item.value,
      };
    }) ?? [];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const currentIndex = selectedCategories.indexOf(value);
    const newSelectedCategories = [...selectedCategories];

    if (currentIndex === -1) {
      newSelectedCategories.push(value);
    } else {
      newSelectedCategories.splice(currentIndex, 1);
    }

    setSelectedCategories(newSelectedCategories);
  };

  const onContinue = () => {
    if (selectedCategories.length === 0) {
      return;
    }

    const categoriesToDispatch = categories.filter((category) =>
      selectedCategories.includes(category.category)
    );

    dispatch(setReceiptClaimCategories(categoriesToDispatch));

    const firstCategory = categoriesToDispatch[0].category;

    navigate(`/payment/submit/receipt/amount/${firstCategory}`);
  };

  return (
    <CardContainer
      title={t("claimDetail_typeOfClaim")}
      subtitle={t("receiptClaim_claimType_description")}
      topRightContent={<ReceiptImagePreview />}
    >
      <form>
        <FormControl component="fieldset" fullWidth>
          {categories.map((category: CategoryAmount) => (
            <FormControlLabel
              key={category.category}
              control={
                <Checkbox
                  data-test-id="checkbox"
                  checked={selectedCategories.includes(category.category)}
                  onChange={handleChange}
                  value={category.category}
                />
              }
              label={
                <Typography data-test-id="category" variant="body1">
                  {category.value}
                </Typography>
              }
            />
          ))}
        </FormControl>

        <Button
          data-test-id="submit-button"
          sx={{ textTransform: "initial", fontWeight: "bold", mt: 2 }}
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          fullWidth
          disabled={selectedCategories.length === 0}
          onClick={onContinue}
        >
          {t("continue_text")}
        </Button>
      </form>
    </CardContainer>
  );
}

export default ReceiptCategoriesPage;
