import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface StudyIDDialogProps {
  open: boolean;
  onClose: () => void;
  studyID: string;
  setStudyID: (id: string) => void;
  onSubmit: (studyID: string) => void;
}

const StudyIDDialog = ({
  open,
  onClose,
  studyID,
  setStudyID,
  onSubmit,
}: StudyIDDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleClose = () => {
    onClose();
  };

  const handleStudyIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStudyID(event.target.value);
  };

  const handleSubmit = () => {
    if (studyID.trim() === "") {
      alert(t("Please enter a valid Study ID."));
      return;
    }
    onSubmit(studyID.trim());
  };

  return (
    <Dialog
      dir={theme.direction}
      data-test-id="study-id-dialog"
      open={open}
      fullWidth
    >
      <DialogTitle data-test-id="study-id-title">
        {t("Enter Your Study ID")}
      </DialogTitle>

      <DialogContent data-test-id="study-id-content">
        <TextField
          data-test-id="study-id-textfield"
          autoFocus
          margin="dense"
          id="studyId"
          label={t("Study ID")}
          type="text"
          fullWidth
          value={studyID}
          onChange={handleStudyIDChange}
          variant="outlined"
        />
      </DialogContent>

      <DialogActions>
        <Button
          data-test-id="secondary-button"
          onClick={handleClose}
          color="primary"
        >
          {t("Cancel")}
        </Button>
        <Button
          data-test-id="primary-button"
          onClick={handleSubmit}
          color="primary"
          variant="contained"
        >
          {t("Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StudyIDDialog;
