import { Navigate, Route, Routes } from "react-router-dom";

import { configService } from "../services/configService";

import PaymentInputForm from "../containers/PaymentInputForm";
import CarersPage from "../pages/CarersPage";
import ChangePINPage from "../pages/ChangePinPage";
import ClaimVisitsPage from "../pages/ClaimVisitsPage";
import CloseAccountPage from "../pages/CloseAccountPage";
import EmailSentPage from "../pages/EmailSentPage";
import EmailValidationPage from "../pages/EmailValidationPage";
import EnableNotificationsPage from "../pages/EnableNotificationsPage";
import LoginPage from "../pages/LoginPage";
import MileageDistancePage from "../pages/MileageDistancePage";
import MileageSummaryPage from "../pages/MileageSummaryPage";
import MileageThresholdPage from "../pages/MileageThresholdPage";
import NotFound from "../pages/NotFound";
import PaymentDetailsPage from "../pages/PaymentDetailsPage";
import PaymentMethods from "../pages/PaymentMethodsPage";
import StipendSummaryPage from "../pages/PaymentSummaryPage";
import PaymentsPage from "../pages/PaymentsPage";
import PrivateRoutes from "../pages/PrivateRoutes";
import ReceiptAmountPage from "../pages/ReceiptAmountPage";
import ReceiptCategoriesPage from "../pages/ReceiptCategoriesPage";
import ReceiptImagePage from "../pages/ReceiptImagePage";
import ReceiptSummaryPage from "../pages/ReceiptSummaryPage";
import RejectStipendPayment from "../pages/RejectStipendPayment";
import SettingsPage from "../pages/SettingsPage";
import Support from "../pages/SupportPage";
import TaxFormPage from "../pages/TaxFormPage";
import TaxOnboardingPage from "../pages/TaxOnboardingPage";
import TermsAndConditions from "../pages/TermsAndConditionsPage";
import TrialDetailsPage from "../pages/TrialDetailsPage";
import VccCardCreated from "../pages/VccCardCreatedPage";
import VccCardPreview from "../pages/VccCardPreview";
import VccCardReview from "../pages/VccCardReviewPage";
import VccCardSetup from "../pages/VccCardSetup";
import VccVisualise from "../pages/VccVisualisePage";
import VehicleQuestionsPage from "../pages/VehicleQuestionsPage";
import VisitsPage from "../pages/VisitsPage";
import PublicRoutes from "../pages/PublicRoutes";

const AppRoutes = () => {
  const showSupportRoute = configService.config.showSupport;

  return (
    <Routes>
      <Route element={<PublicRoutes />}>
        <Route path="/login" element={<LoginPage />} />
      </Route>

      <Route element={<PrivateRoutes />}>
        <Route path="/" element={<Navigate replace to="/payments" />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/carers" element={<CarersPage />} />
        <Route path="/visits" element={<VisitsPage />} />
        <Route path="/trial" element={<TrialDetailsPage />} />
        <Route path="/settings/change-pin" element={<ChangePINPage />} />

        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/vcc-card" element={<VccVisualise />} />
        <Route
          path="/enable-notifications"
          element={<EnableNotificationsPage />}
        />
        <Route path="/payments" element={<PaymentsPage />} />

        {/* SUBMIT PAGES START*/}
        <Route
          path="/settings/submit/close-account/:step?"
          element={<CloseAccountPage />}
        />

        <Route path="/receipt/submit/upload" element={<ReceiptImagePage />} />

        <Route path="/payment/submit/:id/payment-methods" element={<PaymentMethods />} />
        <Route path="/payment/submit/:id/payment-methods/:paymentMethodIndex" element={<PaymentInputForm />} />

        <Route path="/payment/submit/:id/payment-methods/:paymentMethodIndex/preview" element={<VccCardPreview />} />
        <Route path="/payment/submit/:id/payment-methods/:paymentMethodIndex/setup" element={<VccCardSetup />} />
        <Route path="/payment/submit/:id/payment-methods/:paymentMethodIndex/review" element={<VccCardReview />} />
        <Route path="/payment/submit/:id/payment-methods/:paymentMethodIndex/created" element={<VccCardCreated />} />

        <Route path="/payment/submit/:id/visit" element={<ClaimVisitsPage />} />
        <Route
          path="/payment/submit/:id/summary"
          element={<StipendSummaryPage />}
        />

        <Route
          path="/payment/submit/receipt/categories"
          element={<ReceiptCategoriesPage />}
        />
        <Route
          path="/payment/submit/receipt/summary"
          element={<ReceiptSummaryPage />}
        />
        <Route
          path="/payment/submit/receipt/amount/:category"
          element={<ReceiptAmountPage />}
        />

        <Route path="/payment/submit/mileage/distance" element={<MileageDistancePage />} />
        <Route path="/payment/submit/mileage/vehicle" element={<VehicleQuestionsPage />} />
        <Route path="/payment/submit/mileage/threshold" element={<MileageThresholdPage />} />
        <Route path="/payment/submit/mileage/summary" element={<MileageSummaryPage />} />

        {/* SUBMIT PAGES END*/}
        <Route path="/payment/:id/details" element={<PaymentDetailsPage />} />
        <Route path="/payment/:id/decline" element={<RejectStipendPayment />} />
        <Route path="/tax/onboarding" element={<TaxOnboardingPage />} />
        <Route path="/tax/form" element={<TaxFormPage />} />
        <Route
          path="/email/validation/:token"
          element={<EmailValidationPage />}
        />

        <Route path="/email/sent" element={<EmailSentPage />} />
        <Route path="/not-found" element={<NotFound />} />
        {showSupportRoute && <Route path="/support" element={<Support />} />}
        <Route path="*" element={<Navigate to="/not-found" />} />
      </Route >
    </Routes >
  );
};

export default AppRoutes;
