import iqviaTheme from "./iqvia/iqvia-theme";
import nmibleTheme from "./nmible/nmible-theme";
import elligoTheme from "./elligo/elligo-theme";

export const getTheme = () => {
  switch (import.meta.env.VITE_CLIENT.toLowerCase()) {
    case "iqvia":
      return iqviaTheme;

    case "nmible":
      return nmibleTheme;

    case "elligo":
      return elligoTheme;

    default:
      return nmibleTheme;
  }
};
