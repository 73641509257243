import { EvervaultInput } from "@evervault/react";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";

import { AvailablePaymentMethod } from "../../types/paymentTypes";
import FormProperty from "./FormProperty";

type DebitCardFormProps = {
  paymentMethod: AvailablePaymentMethod;
  formState: Record<string, string>;
  errors: { [key: string]: string };
  handleInputChange: (
    propertyPath: string,
    property: any
  ) => (event: any) => void;
  handleEncryptedDetailsChange: (cardData: any) => void;
};

const DebitCardForm: React.FC<DebitCardFormProps> = ({
  paymentMethod,
  formState,
  errors,
  handleInputChange,
  handleEncryptedDetailsChange,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  const cardNumberLabel = paymentMethod.properties.find(
    (item) => item.property_name === "card_number"
  )?.title;

  const expirationDateLabel = paymentMethod.properties.find(
    (item) => item.property_name === "expiration_date"
  )?.title;

  return (
    <div>
      <FormProperty
        paymentMethod={paymentMethod}
        formState={formState}
        property={paymentMethod.properties[0]}
        propertyPath={paymentMethod.properties[0]?.property_name}
        errors={errors}
        handleInputChange={handleInputChange}
      />

      <div style={{ width: "100%", paddingTop: "8px" }}>
        <EvervaultInput
          data-test-id="evervault-inputs"
          config={{
            theme: "material",
            disableCVV: "true",
            height: "180px",
            inputHeight: "56px",
            inputBorderColor: "#C4C4C4",
            primaryColor: primaryColor,
            labelFontSize: "1rem",
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            cardNumberLabel: cardNumberLabel,
            expirationDateLabel: expirationDateLabel,
            invalidCardNumberLabel: t("payment_debitCard_invalidCardNumber"),
            invalidExpirationDateLabel: t(
              "payment_debitCard_invalidExpirationDate"
            ),
          }}
          onChange={handleEncryptedDetailsChange}
        />
      </div>

      <style>{`#ev-iframe {width: 100% !important;}`}</style>
    </div>
  );
};

export default DebitCardForm;
