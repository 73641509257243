import { LoadingButton } from "@mui/lab";
import { TextField, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { sendEnquiryEmail } from "../../api/AuthService";
import { api } from "../../api/api";
import { useErrorHandler } from "../../common/hooks";

interface IOwnProps {
  onNext: () => void;
}

const NotesStep: React.FC<IOwnProps> = ({ onNext }) => {
  const { t } = useTranslation();
  const { handleServerError } = useErrorHandler();

  const [notes, setNotes] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setNotes(value);
  };

  const handleSubmitClick = () => {
    setIsLoading(true);

    api(sendEnquiryEmail(constructEnquiry()))
      .then((response) => {
        onNext();
      })
      .catch((error) => {
        handleServerError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const constructEnquiry = (): string => {
    let text = "Account deletion request.";

    if (notes.trim() !== "") {
      text += `<br/>User reason: ${notes.trim()}`;
    }

    return text;
  };

  return (
    <>
      <Typography data-test-id="close-account-reason-title" variant="h5" mb={1}>
        {" "}
        {t("closeAccount_reason_title")}{" "}
      </Typography>

      <Typography
        data-test-id="close-account-reason-description"
        variant="body2"
      >
        {t("closeAccount_reason_description")}
      </Typography>

      <form>
        <TextField
          data-test-id="close-account-reason-textfield"
          placeholder={t("closeAccount_reason_label")}
          multiline
          rows={4}
          name="notes"
          label={t("closeAccount_reason_label")}
          variant="outlined"
          fullWidth
          margin="normal"
          value={notes}
          onChange={handleInputChange}
        />

        <LoadingButton
          data-test-id="close-account-reason-button"
          sx={{ textTransform: "initial", fontWeight: "bold", mt: 2 }}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={handleSubmitClick}
          loading={isLoading}
          type="submit"
        >
          {isLoading ? t("closeAccount_loadingMessage") : t("closeAccount_title")}
        </LoadingButton>
      </form>
    </>
  );
};

export default NotesStep;
