import { PaymentAccountDetails } from "./paymentTypes";

export interface StipendItem {
  amount?: number;
  claim_id?: number;
  visit_id?: number;
  rate_id?: number;
  id?: number;
  withholding_tax_percentage?: number;
  total_amount?: number;
}

export interface WaitingStipendsResponse {
  claims: [Stipend];
}

export interface Stipend {
  id: number;
  state: PaymentStatus;
  pre_approved: string;
  notes: string | null;
  claimant_id: number;
  created_at: string;
  last_modified_at: string;
  approver_payment_status_changed_at: string | null;
  claimant_payment_status_changed_at?: string | null;
  reviewed_by: number | null;
  claim_owner: string;
  claim_type: "Stipend" | "Receipt" | "Mileage";
  payment_details?: PaymentAccountDetails;
  items?: StipendItem[];
  visit_id: number | null;
  amount: number;
  total_amount: number;
  withholding_tax_percentage: number;
}

export interface DetailsItem {
  label: string;
  value: string | number | undefined;
}

export interface DetailsListProps {
  details: DetailsItem[];
}

interface StipendExtra {
  visit?: string;
}

export interface Payment {
  payee_id: string;
  transaction_id: string;
  amount: number;
  payment_reason: string | null;
  status: PaymentStatus;
  payment_account?: PaymentAccountDetails | null;
  extra?: StipendExtra;
  currency?: string | null;
  action_on?: string | null | undefined;
}

export enum PaymentStatus {
  Pending = "Pending",
  Approved = "Approved",
  Rejected = "Rejected",
  Incomplete = "Incomplete",
}
