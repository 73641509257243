import { Outlet } from "react-router-dom";
import Layout from "../containers/Layout";

const PublicRoutes = () => {
  return (
    <Layout isAuthenticated={false}>
      <Outlet />
    </Layout>
  )
};

export default PublicRoutes;
