import { ChangeEvent, useEffect, useState } from "react";

import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ChangePinData, setPinService } from "../api/AuthService";
import { api } from "../api/api";
import { useErrorHandler } from "../common/hooks";
import CardContainer from "./CardContainer";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ForgetPinButton from "../common/ForgetPinButton";

interface IFormState {
  currentPin: IInputValue;
  newPin: IInputValue;
  newPinConfirmation: IInputValue;
}

interface IInputValue {
  value: string;
  isDirty: boolean;
}

const defaultInputValue = { value: "", isDirty: false };

const ChangePINPage: React.FC = () => {
  const { t } = useTranslation();
  const { handleServerError } = useErrorHandler();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [showPIN, setShowPIN] = useState<boolean>(false);

  const [formState, setFormState] = useState<IFormState>({
    currentPin: defaultInputValue,
    newPin: defaultInputValue,
    newPinConfirmation: defaultInputValue,
  });

  useEffect(() => {
    const validationResult = validateForm();

    setIsFormValid(validationResult);
  }, [formState]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const key = event.target.name;
    const value = event.target.value;

    setFormState((prevState) => ({
      ...prevState,
      [key]: { value, isDirty: true },
    }));
  };

  const validateForm = () => {
    setErrors((prevState) => ({}));

    let isValid = true;

    if (
      !formState.currentPin.value ||
      !formState.newPin.value ||
      !formState.newPinConfirmation.value
    ) {
      isValid = false;
    }

    if (!formState.currentPin.value && formState.currentPin.isDirty) {
      setErrors((prevState) => ({
        ...prevState,
        currentPin: t("enterPin_title"),
      }));

      isValid = false;
    }

    if (formState.currentPin.value && formState.currentPin.value.length < 6) {
      setErrors((prevState) => ({
        ...prevState,
        currentPin: t("choosePin_subtitle"),
      }));

      isValid = false;
    }

    if (formState.newPin.value && formState.newPinConfirmation.value) {
      if (formState.newPin.value !== formState.newPinConfirmation.value) {
        setErrors((prevState) => ({
          ...prevState,
          newPin: t("error_notMatchedPin"),
          newPinConfirmation: t("error_notMatchedPin"),
        }));

        isValid = false;
      }
    }

    if (formState.newPin.value && formState.newPin.value.length < 6) {
      setErrors((prevState) => ({
        ...prevState,
        newPin: t("choosePin_subtitle"),
      }));

      isValid = false;
    }

    return isValid;
  };

  const handleSubmitClick = () => {
    setIsLoading(true);

    let requestData: ChangePinData = {
      current_pin: formState.currentPin.value,
      new_pin: formState.newPin.value,
    };

    api(setPinService(requestData))
      .then((response) => {
        setIsSuccessful(true);
        setFormState({
          currentPin: defaultInputValue,
          newPin: defaultInputValue,
          newPinConfirmation: defaultInputValue,
        });
      })
      .catch((error) => {
        setIsSuccessful(false);

        // Other input errors are handled on the FE.
        if (
          error.response?.status === 400 &&
          error.response?.data?.detail?.length
        ) {
          if (error.response?.data.detail[0].field == "current_pin") {
            setErrors((prevState) => ({
              ...prevState,
              currentPin: t("error_wrongPin"),
            }));
          }
        }

        handleServerError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClickShowPIN = () => {
    setShowPIN((show) => !show);
  };

  return (
    <Grid>
      <CardContainer
        title={t("settings_changePin")}
        subtitle={t("change_pin_subtitle")}
      >
        {isSuccessful && (
          <Alert data-test-id="success-msg" severity="success">
            <div data-test-id="success-msg-p1">
              {t("changePin_pinChangedConfirmationMessage_p1")}
            </div>
            <div data-test-id="success-msg-p2">
              {t("changePin_pinChangedConfirmationMessage_p2")}
            </div>
          </Alert>
        )}
        <form>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel htmlFor="outlined-adornment-current-pin">
              {t("current_pin")}
            </InputLabel>

            <OutlinedInput
              name="currentPin"
              error={!!errors["currentPin"]}
              onChange={handleInputChange}
              onKeyPress={(event) => {
                if (/[^0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              value={formState.currentPin.value}
              data-test-id="current-pin-textfield"
              type={showPIN ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPIN}
                    edge="end"
                  >
                    {showPIN ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label={t("current_pin")}
            />

            {!!errors["currentPin"] && (
              <FormHelperText error id="accountId-error">
                {errors["currentPin"]}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel htmlFor="outlined-adornment-new-pin">
              {t("changePin_chooseNewPin")}
            </InputLabel>

            <OutlinedInput
              name="newPin"
              error={!!errors["newPin"]}
              onChange={handleInputChange}
              onKeyPress={(event) => {
                if (/[^0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              value={formState.newPin.value}
              data-test-id="new-pin-textfield"
              type={showPIN ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPIN}
                    edge="end"
                  >
                    {showPIN ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label={t("changePin_chooseNewPin")}
            />

            {!!errors["newPin"] && (
              <FormHelperText error id="accountId-error">
                {errors["newPin"]}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel htmlFor="outlined-adornment-confirm-pin">
              {t("changePin_confirmNewPinSubtitle")}
            </InputLabel>

            <OutlinedInput
              name="newPinConfirmation"
              error={!!errors["newPinConfirmation"]}
              onChange={handleInputChange}
              onKeyPress={(event) => {
                if (/[^0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              value={formState.newPinConfirmation.value}
              data-test-id="confirm-pin-textfield"
              type={showPIN ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPIN}
                    edge="end"
                  >
                    {showPIN ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label={t("changePin_confirmNewPinSubtitle")}
            />

            {!!errors["newPinConfirmation"] && (
              <FormHelperText error id="accountId-error">
                {errors["newPinConfirmation"]}
              </FormHelperText>
            )}
          </FormControl>

          <LoadingButton
            data-test-id="submit-button"
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            sx={{ mt: 2, mb: 2 }}
            loading={isLoading}
            onClick={handleSubmitClick}
            disabled={!isFormValid}
          >
            {t("continue_text")}
          </LoadingButton>

          <ForgetPinButton />
        </form>
      </CardContainer>
    </Grid>
  );
};

export default ChangePINPage;
