import { AxiosRequestConfig } from "axios";

export const refreshTokenService = (
  refreshToken: string
): AxiosRequestConfig => {
  return {
    url: "/v1/auth/payee-refresh",
    method: "POST",
    data: {
      refresh_token: refreshToken,
    },
  };
};

export interface ParticipantLoginRequestData {
  participant_number: string;
  year_of_birth: number;
  pin?: string;
}

export interface CarerLoginRequestData {
  participant_number: string;
  code: string;
  pin?: string;
}

export const participantLoginService = (
  data: ParticipantLoginRequestData,
  server?: string
): AxiosRequestConfig => {
  return {
    baseURL: server,
    url: "/token/participant/",
    method: "POST",
    data: data,
  };
};

export const carerLoginService = (
  data: CarerLoginRequestData,
  server?: string
): AxiosRequestConfig => {
  return {
    baseURL: server,
    url: "/token/carer/",
    method: "POST",
    data: data,
  };
};

export const getStudy = (): AxiosRequestConfig => {
  return {
    url: "/claimants/study/",
    method: "Get",
  };
};

export interface TaxInfo {
  email: string;
  full_name: string;
  is_paper_copy_requested: boolean;
}

export const updateTaxInfo = (data: TaxInfo): AxiosRequestConfig => {
  return {
    url: "/claimants/edit-email",
    method: "PUT",
    data: data,
  };
};

export const verifyEmail = (token: string): AxiosRequestConfig => {
  return {
    url: "/claimants/verify-email",
    method: "POST",
    data: { token: token },
  };
};

export const verifyPin = (pin: string): AxiosRequestConfig => {
  return {
    url: "/claimants/verify-pin",
    method: "POST",
    data: { pin },
  };
};

export const setTaxInfo = (data: TaxInfo): AxiosRequestConfig => {
  return {
    url: "/claimants/set-email",
    method: "POST",
    data: data,
  };
};

export const sendEnquiryEmail = (text: string): AxiosRequestConfig => {
  return {
    url: "/claimants/enquiry/",
    method: "POST",
    data: { text },
  };
};

export interface SetPinData {
  new_pin: string;
}

export interface ChangePinData {
  current_pin: string;
  new_pin: string;
}

export const setPinService = (
  data: SetPinData | ChangePinData
): AxiosRequestConfig => {
  return {
    url: "/claimants/change-pin/",
    method: "PUT",
    data: data,
  };
};
