import React, { useState } from "react";
import AppHeader from "../components/AppHeader/AppHeader";
import { Box, Typography } from "@mui/material";
import AppMenu from "../components/AppMenu";
import { useTitle } from "../common/TitleContext";
import AppRoutes from "../router/AppRoutes";

/**
 * @type {import('@mui/material').SxProps}
 */
const styles = {
  pageTitle: {
    textAlign: "left",
    mb: 2,
    fontWeight: "bold",
    color: "primary.main",
  },
  container: {
    display: "flex",
    height: "calc(100% - 64px)",
  },
  mainSection: {
    p: 4,
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
};

interface LayoutProps {
  isAuthenticated: boolean;
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ isAuthenticated, children }) => {
  const { title } = useTitle();

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  return (
    <>
      {isAuthenticated ? (
        <AppHeader
          onSidebarCollapse={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
        />
      ) : null}

      <Box sx={isAuthenticated ? styles.container : null}>
        {isAuthenticated ? (
          <AppMenu
            collapsed={isSidebarCollapsed}
            onSidebarCollapse={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
          />
        ) : null}

        <Box component={"main"} sx={styles.mainSection}>
          {title.length > 0 && (
            <Typography
              data-test-id="navbar-title"
              variant="h4"
              component="h1"
              sx={styles.pageTitle}
            >
              {title}
            </Typography>
          )}

          {children}
        </Box>
      </Box>
    </>
  )
};

export default Layout;
