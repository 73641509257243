import AddCardIcon from "@mui/icons-material/AddCard";
import { Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { usePageTitle } from "../common/TitleContext";
import { getPaymentMethodByType } from "../redux/slices/paymentMethodsSlice";
import { RootState } from "../redux/store";
import { PaymentType } from "../types/paymentTypes";

const VccCardPreview: React.FC = () => {
  const navigate = useNavigate();

  const vccPaymentMethod = useSelector((state: RootState) => {
    return getPaymentMethodByType(state, PaymentType.VirtualCard);
  });

  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  usePageTitle(vccPaymentMethod?.title || t("virtualCard_title"));

  const imgAspectRatioPadding = "56.25%";

  return (
    <div>
      <div
        style={{
          width: "100%",
          paddingTop: imgAspectRatioPadding,
          position: "relative",
        }}
      >
        <img
          data-test-id="vcc-preview-image"
          src="/images/VCC.svg"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          alt="virtual card preview"
        />
      </div>

      <Typography
        variant="h6"
        component="h6"
        fontWeight="500"
        color="text.secondary"
        textAlign="left"
        pt="20px"
        pb="20px"
        data-test-id="vcc-preview-title"
      >
        {t("virtualCard_whatIsItTitle")}
      </Typography>

      <Typography
        data-test-id="vcc-preview-description"
        fontSize="14px"
        color="text.primary"
        pb="20px"
      >
        {t("virtualCard_whatIsItDescription")}
      </Typography>

      <Button
        data-test-id="vcc-preview-button"
        fullWidth
        variant="contained"
        size="large"
        startIcon={<AddCardIcon />}
        onClick={() =>
          navigate(`/payment/submit/${id}/payment-methods/VCC/setup`)
        }
        style={{ textTransform: "uppercase" }}
      >
        {t("continue_text")}
      </Button>
    </div>
  );
};

export default VccCardPreview;
