import { CircularProgress } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../api/api";
import paymentService from "../api/paymentService";
import { getClaimById } from "../api/stipendsServices";
import { usePageTitle } from "../common/TitleContext";
import { useErrorHandler } from "../common/hooks";
import { useVisits } from "../common/useVisits";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { resetCarerClaims, updateClaims } from "../redux/slices/claimsSlice";
import { showToast } from "../redux/slices/toastSlice";
import { RootState } from "../redux/store";
import { Visit } from "../types/common";
import { Claim } from "../types/paymentTypes";
import getPaymentDetails from "./PaymentDetailsProvider";
import SummaryPageUI from "./SummaryPageUI";

function PaymentDetailsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleServerError } = useErrorHandler();

  const { visits } = useVisits();
  const [visit, setVisit] = useState<Visit | null>(null);
  const { id } = useParams<{ id: string }>();

  const searchParams = new URLSearchParams(location.search);
  const isCarerOwner = searchParams.get("owner") === "carer";

  usePageTitle(t("payment_title"));

  // If we come from login redirect the claims are still not loaded so we load just this one claim
  const loadClaimById = (id: string) => {
    api<Claim>(getClaimById(id))
      .then((response) => {
        const claims = [response.data];

        dispatch(updateClaims({ claims: claims, page: 1 }));
      })
      .catch((error) => {
        handleServerError(error);
      });
  };

  const claim = useSelector((state: RootState) => {
    const source = isCarerOwner
      ? state.claims.carerClaims
      : state.claims.claims;

    const claimData = source.find((claim) => {
      let result = claim.id === parseInt(id ?? "-1");

      return result;
    }) as Claim;

    return claimData;
  });

  useEffect(() => {
    if (!claim) {
      loadClaimById(id ?? "-1");
    }
  }, [id]);

  useEffect(() => {
    if (claim?.visit_id) {
      let visit = visits.find((item) => {
        return item.id === claim?.visit_id;
      });

      if (visit) {
        setVisit(visit);
      }
    }
  }, [claim, visits.length]);

  const [isApproving, setApproving] = useState(false);
  const [isRejecting, setRejecting] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({
    title: "",
    message: "",
    confirmButton: "",
    onConfirm: () => {},
  });

  const handleApprove = (claim: Claim) => {
    setDialogConfig({
      title: t("claimDetail_approveClaim"),
      message: t("claimDetail_approveClaimConfirmationMessage"),
      confirmButton: t("claimDetail_approve"),
      onConfirm: () => handleConfirmApprove(claim.id),
    });

    setDialogOpen(true);
  };

  const handleReject = (claim: Claim) => {
    setDialogConfig({
      title: t("claimDetail_rejectClaim"),
      message: t("claimDetail_rejectClaimConfirmationMessage"),
      confirmButton: t("claimDetail_reject"),
      onConfirm: () => handleConfirmReject(claim.id),
    });

    setDialogOpen(true);
  };

  const handleConfirmApprove = async (claimId: number) => {
    setDialogOpen(false);
    setApproving(true);

    try {
      await api(paymentService.approveClaim(claimId));
      dispatch(showToast(t("claim_toastConfirmationMessage")));
      dispatch(resetCarerClaims());
    } catch (error) {
      handleServerError(error as AxiosError);
    } finally {
      navigate("/payments");
      setApproving(false);
    }
  };

  const handleConfirmReject = async (claimId: number) => {
    setDialogOpen(false);
    setRejecting(true);

    try {
      await api(paymentService.rejectClaim(claimId));
      dispatch(showToast(t("claimDetail_claimRejected")));
      dispatch(resetCarerClaims());
    } catch (error) {
      handleServerError(error as AxiosError);
    } finally {
      navigate("/payments");
      setRejecting(false);
    }
  };

  if (!claim) {
    return <CircularProgress />;
  }

  return (
    <>
      <SummaryPageUI
        {...getPaymentDetails(
          claim,
          visit,
          handleApprove,
          handleReject,
          isApproving,
          isRejecting
        )}
      />

      <ConfirmationDialog
        open={isDialogOpen}
        title={dialogConfig.title}
        message={dialogConfig.message}
        confirmButtonText={dialogConfig.confirmButton}
        cancelButtonText={t("cancel")}
        onConfirm={dialogConfig.onConfirm}
        onCancel={() => setDialogOpen(false)}
      />
    </>
  );
}

export default PaymentDetailsPage;
