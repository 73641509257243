import React, { useEffect, useState } from "react";
import { CountryCode } from "libphonenumber-js";


import { AvailablePaymentMethod, PaymentMethodProp, PropertyClientType, StateItem } from "../../types/paymentTypes";
import FormProperty from "./FormProperty";
import { Grid } from "@mui/material";
import AddressSearch from "../AddressSearch";
import { removeSpacesAndHyphens, stripTextAfterHyphen } from "../../common/helpers";
import StateAutocomplete from "../AutoComplete/StateAutoComplete";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

type AddressFormProps = {
    paymentMethod: AvailablePaymentMethod,
    property: PaymentMethodProp,
    formState: Record<string, string>,
    errors: { [key: string]: string },
    handleInputChange: (propertyPath: string, property: any) => (event: any) => void;
};

const AddressForm: React.FC<AddressFormProps> = ({ paymentMethod, property, formState, errors, handleInputChange }) => {
    const { t } = useTranslation();

    const [isAddressEnabled, setIsAddressEnabled] = useState<boolean>(false);

    const statesList = useSelector((state: RootState) => {
        return state.paymentMethods.states;
    });

    useEffect(() => {
        // Filter and find keys in formState that start with 'address.' and have a value
        const filledAddressItems = Object.keys(formState).reduce((acc: any, key) => {
            if (key.startsWith(`${property.property_name}.`) && formState[key]) {
                acc[key] = formState[key];
            }

            return acc;
        }, {});

        if (Object.keys(filledAddressItems).length > 0) {
            setIsAddressEnabled(true);
        }
    }, []);

    const handleProvinceUpdate = (province: string) => {
        const matchedState = statesList.find((state: StateItem) => state.name.toLowerCase() === province.toLowerCase());

        if (matchedState) {
            handleInputChange(`${property.property_name}.${getPropNameByClientType(PropertyClientType.State)}`, {
                property_name: `${property.property_name}.${getPropNameByClientType(PropertyClientType.State)}`,
            })({
                target: { value: matchedState?.code },
            });
        }
    };

    const getPropNameByClientType = (type: PropertyClientType) => {
        return property?.nestedProps?.find((nestedProp: PaymentMethodProp) => nestedProp.client_type == type)?.property_name;
    }

    return (
        <>
            <Grid item mb={2} key="search" sx={{ fontSize: "0.875rem" }}>
                <AddressSearch
                    locale="en-US"
                    apiKey={import.meta.env.VITE_LOQATE_KEY || ""}
                    countries={[paymentMethod.country as CountryCode]}
                    className="address-search-box"
                    classes={{ list: "custom-list-item" }}
                    inline
                    debounce={100}
                    data-test-id="search"
                    manualEntryText={t("paymentMethod_enterAddressManually") ?? ""}
                    onManualEntrySelected={() => setIsAddressEnabled(true)}
                    onSelect={(address) => {
                        handleInputChange(`${property.property_name}.${getPropNameByClientType(PropertyClientType.Line1)}`, {
                            property_name: `${property.property_name}.${getPropNameByClientType(PropertyClientType.Line1)}`,
                        })({
                            target: { value: address.Line1 },
                        });

                        handleInputChange(`${property.property_name}.${getPropNameByClientType(PropertyClientType.City)}`, {
                            property_name: `${property.property_name}.${getPropNameByClientType(PropertyClientType.City)}`,
                        })({
                            target: { value: address.City },
                        });

                        handleInputChange(`${property.property_name}.${getPropNameByClientType(PropertyClientType.ZIP)}`, {
                            property_name: `${property.property_name}.${getPropNameByClientType(PropertyClientType.ZIP)}`,
                        })({
                            target: { value: removeSpacesAndHyphens(stripTextAfterHyphen(address.PostalCode)) },
                        });

                        if (address.ProvinceName) {
                            handleProvinceUpdate(address.ProvinceName);
                        }

                        setIsAddressEnabled(true);
                    }}
                />
            </Grid>

            <Grid
                item
                width="100%"
                p={2}
                borderRadius="4px"
                border="1px solid grey"
                sx={{ marginBottom: "16px" }}
            >
                {property?.nestedProps?.filter((nestedProp: PaymentMethodProp) => {
                    return nestedProp.client_type !== PropertyClientType.Country
                })
                    .map((nestedProp: PaymentMethodProp) => {
                        return (
                            <Grid item mb={2} key={nestedProp.property_name} sx={{ fontSize: "0.875rem" }}>
                                {nestedProp.client_type !== PropertyClientType.State && (
                                    <FormProperty
                                        property={nestedProp}
                                        propertyPath={`${property.property_name}.${nestedProp.property_name}`}
                                        paymentMethod={paymentMethod}
                                        formState={formState}
                                        handleInputChange={handleInputChange}
                                        errors={errors}
                                        isDisabled={!isAddressEnabled}
                                    />
                                )}


                                {nestedProp.client_type === PropertyClientType.State && (
                                    <StateAutocomplete
                                        countryCode={paymentMethod.country}
                                        label={nestedProp.title}
                                        data-test-id="state-autocomplete"
                                        selectedStateCode={formState[`${property.property_name}.${nestedProp.property_name}`]}
                                        handleStateChange={(event: any, newValue: any) => {
                                            handleInputChange(`${property.property_name}.${nestedProp.property_name}`, {
                                                property_name: `${property.property_name}.${nestedProp.property_name}`,
                                            })({
                                                target: { value: newValue?.code || "" },
                                            });
                                        }}
                                        disabled={!isAddressEnabled}
                                    />
                                )}
                            </Grid>
                        )
                    })}
            </Grid>
        </>
    );
};

export default AddressForm;
