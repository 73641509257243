import { AxiosRequestConfig } from "axios";

export const emailValidationService = (email: string): AxiosRequestConfig => {
  return {
    url: "/EmailValidation/Interactive/Validate/v2.00/json3.ws",
    method: "GET",
    params: {
      Key: import.meta.env.VITE_LOQATE_KEY,
      Email: email,
    },
  };
};

export const phoneValidationService = (
  phone: string,
  countryCode: string
): AxiosRequestConfig => {
  return {
    url: "/PhoneNumberValidation/Interactive/Validate/v2.2/json3.ws",
    method: "GET",
    params: {
      Key: import.meta.env.VITE_LOQATE_KEY,
      Phone: phone,
      Country: countryCode,
    },
  };
};
