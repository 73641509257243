import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AvailablePaymentMethod,
  PaymentAccountDetails,
  PaymentType,
  StateItem,
} from "../../types/paymentTypes";
import { RootState } from "../store";

export const RESET_STATE = "RESET_STATE";

interface PaymentMethodsState {
  latestPaymentAccount: PaymentAccountDetails | null;
  formStep: number;
  states: StateItem[];
}

const initialState: PaymentMethodsState = {
  latestPaymentAccount: null,
  formStep: 1,
  states: [],
};

export const paymentMethodsSlice = createSlice({
  name: "paymentMethods",
  initialState,
  reducers: {
    setLatestPaymentMethod: (
      state,
      action: PayloadAction<PaymentAccountDetails>
    ) => {
      state.latestPaymentAccount = action.payload;
    },
    setFormStep: (state, action: PayloadAction<number>) => {
      state.formStep = action.payload;
    },
    setStates: (state, action: PayloadAction<StateItem[]>) => {
      state.states = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return initialState;
    });
  },
});

export const paymentMethodsList = (state: RootState) =>
  state.auth.profile?.available_payment_methods;
export const paymentMethodType = (
  state: RootState,
  paymentMethodType: PaymentType
) => paymentMethodType;

export const getPaymentMethodByType = createSelector(
  [paymentMethodsList, paymentMethodType],
  (paymentMethods, paymentMethodType): AvailablePaymentMethod | undefined => {
    return paymentMethods?.find((x) => x.payment_type === paymentMethodType);
  }
);

export const getPaymentMethodIdByType = createSelector(
  [paymentMethodsList, paymentMethodType],
  (paymentMethods, paymentMethodType): number | undefined => {
    return paymentMethods?.find((x) => x.payment_type === paymentMethodType)
      ?.id;
  }
);

export const { setStates, setLatestPaymentMethod, setFormStep } =
  paymentMethodsSlice.actions;
export default paymentMethodsSlice.reducer;
