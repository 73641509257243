import { AxiosRequestConfig } from "axios";
import {
  BankAccountData,
  DebitCardData,
  VccPaymentData,
} from "../types/paymentTypes";

export const getLatestPaymentMethod = (): AxiosRequestConfig => {
  return {
    url: "/claimants/payments/latest-payment",
    method: "GET",
  };
};
export const getStates = (countryCode: string): AxiosRequestConfig => {
  return {
    url: `/states/${countryCode}/`,
    method: "GET",
  };
};

// export const getPaymentMethods = (): AxiosRequestConfig => {
//   return {
//     url: "/v1/payment-methods",
//     method: "GET",
//   };
// };

export const createDebitCard = (data: DebitCardData): AxiosRequestConfig => {
  return {
    url: "/v1/payment-methods/debit-card",
    method: "POST",
    data: data,
  };
};

export const createBankAccount = (
  data: BankAccountData
): AxiosRequestConfig => {
  return {
    url: "/v1/payment-methods/bank-account",
    method: "POST",
    data: data,
  };
};

export const createVirtualCard = (data: VccPaymentData): AxiosRequestConfig => {
  return {
    url: "/claimants/payments/vcc",
    method: "POST",
    data: data,
  };
};

export const getVirtualCard = (): AxiosRequestConfig => {
  return {
    url: "/claimants/payments/vcc",
    method: "GET",
  };
};
