import * as Sentry from "@sentry/browser";
import { AxiosError } from "axios";
import { TFunction } from "i18next";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Error from "../components/Error";
import { configService } from "../services/configService";

interface CustomErrorBoundaryProps {
  children: React.ReactNode;
  onCatch?: (hasError: boolean) => void;
}

interface TermsErrorResponse {
  detail?: {
    first_time_user?: boolean;
  };
}

interface ErrorProps {
  title: string;
  message: string;
  statusCode?: number;
  icon: string;
  buttonText: string;
  isRefreshBtn?: boolean;
}

interface FallbackProps {
  error: any;
  resetErrorBoundary: () => void;
}

interface ErrorResponseData {
  detail?: { message?: string; msg?: string }[];
}

export enum ErrorType {
  UNAUTHORIZED = "UNAUTHORIZED",
  TERMS_UPDATED = "TERMS_UPDATED",
  TERMS_NAVIGATE = "TERMS_NAVIGATE",
  NO_CONNECTION = "NO_CONNECTION",
  RUNTIME = "RUNTIME",
  GENERAL = "GENERAL",
}

export interface ErrorDetails {
  type: ErrorType;
  method: string | undefined;
  props?: ErrorProps;
}

const getErrorType = (error: AxiosError): ErrorType => {
  const statusCode = error.response?.status;

  if (statusCode === 401 || statusCode === 403) {
    return ErrorType.UNAUTHORIZED;
  }

  if (statusCode === 409) {
    let termsError = error.response?.data as TermsErrorResponse;
    let isFirstTimeUser = termsError.detail?.first_time_user;

    return isFirstTimeUser === true
      ? ErrorType.TERMS_NAVIGATE
      : ErrorType.TERMS_UPDATED;
  }

  const cantConnectStatusCodes = [599, 522, 524, 523, 503, 500, 408];

  if (!error.response || cantConnectStatusCodes.includes(statusCode ?? -1)) {
    return ErrorType.NO_CONNECTION;
  }

  return ErrorType.GENERAL;
};

export const getErrorDetails = (
  error: AxiosError,
  t: TFunction
): ErrorDetails | undefined => {
  let statusCode: number | undefined;
  let message: string | undefined;

  const method = error.config?.method;

  statusCode = error.response?.status;
  const errorType = getErrorType(error);

  const detailsArray = (error.response?.data as ErrorResponseData)?.detail;

  if (detailsArray && Array.isArray(detailsArray)) {
    message = detailsArray
      ?.map((detail) => detail.message || detail.msg)
      .join("\n");
  }

  if (errorType === ErrorType.UNAUTHORIZED) {
    return {
      type: errorType,
      method,
    };
  }

  if (
    errorType === ErrorType.TERMS_NAVIGATE ||
    errorType === ErrorType.TERMS_UPDATED
  ) {
    return {
      type: errorType,
      method,
      props: {
        title: t("home_termsTitle"),
        message: t("home_termsSubtitle", {
          client: configService.config.clientName,
        }),
        icon: "/images/updated_documents.svg",
        isRefreshBtn: false,
        buttonText: t("home_termsButtonTitle"),
      },
    };
  }

  if (errorType === ErrorType.NO_CONNECTION) {
    return {
      type: errorType,
      method,
      props: {
        title: t("connectionLost_title"),
        message: t("connectionLost_message"),
        statusCode: statusCode,
        icon: "/images/no_connection.svg",
        buttonText: t("retry"),
      },
    };
  }

  return {
    type: errorType,
    method,
    props: {
      title: t("error"),
      message: message ?? t("error_somethingWentWrong"),
      statusCode: statusCode,
      icon: "/images/server_warning.svg",
      buttonText: t("retry"),
    },
  };
};

const CustomErrorBoundary: React.FC<CustomErrorBoundaryProps> = ({
  children,
  onCatch,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const errorHandler = (error: unknown): ErrorDetails | undefined => {
    if (error instanceof AxiosError) {
      const details = getErrorDetails(error, t);

      if (details?.type === ErrorType.UNAUTHORIZED) {
        navigate("/login", { replace: true });

        return;
      }

      if (details?.type === ErrorType.TERMS_NAVIGATE) {
        navigate("/terms-and-conditions", { replace: true });

        return;
      }

      return details;
    }
  };

  const buttonAction = (
    errorType: ErrorType,
    resetErrorBoundary: () => void
  ): (() => void) => {
    switch (errorType) {
      case ErrorType.TERMS_UPDATED:
        return () => navigate("/terms-and-conditions", { replace: true });

      default:
        return () => {
          if (onCatch) {
            onCatch(false);
          }

          resetErrorBoundary();
        };
    }
  };

  const FallbackComponent: React.FC<FallbackProps> = ({
    error,
    resetErrorBoundary,
  }) => {
    const errorDetails = errorHandler(error);

    if (!errorDetails || !errorDetails.props) {
      return null;
    }

    const { type, props } = errorDetails;

    return (
      <Error {...props} buttonAction={buttonAction(type, resetErrorBoundary)} />
    );
  };

  return (
    <ErrorBoundary
      key={location.pathname}
      FallbackComponent={FallbackComponent}
      onError={(error, info) => {
        if (onCatch) {
          onCatch(true);
        }

        const errorType =
          error instanceof AxiosError ? getErrorType(error) : ErrorType.RUNTIME;

        if (errorType === ErrorType.UNAUTHORIZED) {
          navigate("/login", { replace: true });

          return;
        }

        if (
          errorType !== ErrorType.TERMS_NAVIGATE &&
          errorType !== ErrorType.TERMS_UPDATED
        ) {
          console.error("Report the error", error, info);

          // Sentry.captureException(error, {
          //   extra: {
          //     errorInfo: info,
          //   },
          // });
        }
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default CustomErrorBoundary;
