import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

interface SupportedLanguages {
  [language: string]: {
    regions: Region[];
    default: string;
  };
}
interface Region {
  id: string;
  name: string;
}
interface LocaleResult {
  locale: string;
  usedFallback: boolean;
}

function getLocale(
  languageIdentifier: string,
  supportedLanguages: SupportedLanguages,
  countryCode?: string
): LocaleResult {
  const parts = languageIdentifier.split("-");
  const language = parts[0].toLowerCase();
  const region = parts.length > 1 ? parts[1].toUpperCase() : undefined;

  // First priority is given to the provided countryCode, if valid
  const regionalIdentifier = countryCode
    ? `${language}-${countryCode.toUpperCase()}`
    : region
    ? `${language}-${region}`
    : language;

  // Check if the language is supported
  if (supportedLanguages[language]) {
    // Check for exact regional match or a match with countryCode
    if (
      regionalIdentifier &&
      supportedLanguages[language].regions
        .map((item) => item.id)
        .includes(regionalIdentifier)
    ) {
      return { locale: regionalIdentifier, usedFallback: false };
    }

    // Fallback to the default for the language
    return {
      locale: supportedLanguages[language].default,
      usedFallback: false,
    };
  }

  // Fallback to 'en-GB' if language not supported
  return { locale: "en-GB", usedFallback: true };
}

const customDetector = (lng: string) => {
  const determinedLocale = getLocale(lng, supportedLanguages);

  return determinedLocale.locale;
};

export const supportedLanguages: SupportedLanguages = {
  bg: {
    regions: [{ id: "bg", name: "Български" }],
    default: "bg",
  },
  de: {
    regions: [{ id: "de", name: "Deutsch" }],
    default: "de",
  },
  en: {
    regions: [{ id: "en-GB", name: "English" }],
    default: "en-GB",
  },
  es: {
    regions: [
      { id: "es-ES", name: "Español" },
      { id: "es-419", name: "Español (Latinoamérica)" },
    ],
    default: "es-ES",
  },
  fr: {
    regions: [{ id: "fr-FR", name: "Français" }],
    default: "fr-FR",
  },
  pt: {
    regions: [{ id: "pt", name: "Português" }],
    default: "pt",
  },
  vi: {
    regions: [{ id: "vi", name: "Tiếng Việt" }],
    default: "vi",
  },
  zh: {
    regions: [{ id: "zh", name: "华语" }],
    default: "zh",
  },
  it: {
    regions: [{ id: "it-IT", name: "Italiano" }],
    default: "it-IT",
  },
  ja: {
    regions: [{ id: "ja-JP", name: "日本語" }],
    default: "ja-JP",
  },
  ko: {
    regions: [{ id: "ko-KR", name: "한국어" }],
    default: "ko-KR",
  },
  ar: {
    regions: [{ id: "ar", name: "العربية" }],
    default: "ar",
  },
  cs: {
    regions: [{ id: "cs", name: "Čeština" }],
    default: "cs",
  },
  da: {
    regions: [{ id: "da", name: "Dansk" }],
    default: "da",
  },
  et: {
    regions: [{ id: "et", name: "Eesti" }],
    default: "et",
  },
  lt: {
    regions: [{ id: "lt", name: "Lietuvių" }],
    default: "lt",
  },
  nl: {
    regions: [{ id: "nl", name: "Nederlands" }],
    default: "nl",
  },
  no: {
    regions: [{ id: "no-NO", name: "Norsk" }],
    default: "no-NO",
  },
  pl: {
    regions: [{ id: "pl", name: "Polski" }],
    default: "pl",
  },
  ru: {
    regions: [{ id: "ru", name: "Русский" }],
    default: "ru",
  },
  th: {
    regions: [{ id: "th", name: "ไทย" }],
    default: "th",
  },
};

export const getRegionById = (regionId: string): Region | undefined => {
  for (const languageKey in supportedLanguages) {
    const language = supportedLanguages[languageKey];
    const region = language.regions.find((item) => item.id === regionId);
    if (region) {
      return region;
    }
  }

  return undefined;
};

export const getAllRegions = (): Region[] => {
  const regions: Region[] = [];
  Object.values(supportedLanguages).forEach((language) => {
    regions.push(...language.regions);
  });
  return regions;
};

export const changeUserLanguage = (language: string): LocaleResult => {
  const localeResult = getLocale(language ?? "en-GB", supportedLanguages);

  i18n.changeLanguage(localeResult.locale);

  return localeResult;
};

const i18nInstance = i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "en-GB",
    supportedLngs: Object.keys(supportedLanguages).flatMap((lang) =>
      supportedLanguages[lang].regions.map((item) => item.id)
    ),
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["localStorage"],
      caches: ["localStorage"],
      lookupQuerystring: "lng",
      convertDetectedLanguage: customDetector,
    },
    backend: {
      loadPath: "/locales/{{lng}}.json",
    },
    react: {
      useSuspense: false,
    },
  });

export default i18nInstance;
