import { api } from "../api/api";
import paymentService, {
  DistanceCalculationResult,
} from "../api/paymentService";
import { setMileageClaimDistance } from "../redux/slices/claimsSlice";
import { store } from "../redux/store";

interface MileageRateCalculationResult {
  isThresholdExceeded: boolean;
  rateCalculationResults: DistanceCalculationResult;
}

export async function calculateMileageRate(
  distance: number,
  distance_unit: string
): Promise<MileageRateCalculationResult> {
  const response = await api<DistanceCalculationResult>(
    paymentService.calculateMileageRate(distance)
  );

  const rateCalculationResults = response.data;

  store.dispatch(
    setMileageClaimDistance({
      unit: distance_unit,
      rateCalculationResults: rateCalculationResults,
    })
  );

  const distance_threshold = rateCalculationResults.distance_threshold ?? 0;
  const isThresholdExceeded = distance_threshold > 0;

  return {
    isThresholdExceeded,
    rateCalculationResults,
  };
}
