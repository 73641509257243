import { Box } from "@mui/material";
import { DropzoneArea } from "mui-file-dropzone";
import { useTranslation } from "react-i18next";

interface ImageUploaderProps {
  files: File[];
  onFileChange: (file: File[]) => void;
}

export default function ImageUploader({
  files,
  onFileChange,
}: ImageUploaderProps) {
  const { t } = useTranslation();

  return (
    <Box>
      <DropzoneArea
        data-test-id="uploader"
        fileObjects={files}
        filesLimit={1}
        maxFileSize={7340032} // 5 mb
        dropzoneText={t("drag_and_drop")}
        acceptedFiles={["image/*"]}
        disableRejectionFeedback={true}
        // showPreviews={true}
        showPreviewsInDropzone={false}
        showAlerts={["error", "info"]}
        onChange={(loadedFiles) => {
          onFileChange(loadedFiles);
        }}
      />
    </Box>
  );
}
