import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { setVisits, setVisitsLoading } from "../redux/slices/visitsSlice";
import { api } from "../api/api";
import { VisitsResponse } from "../types/common";
import { getVisits } from "../api/visitsService";
import { useErrorBoundary } from "react-error-boundary";

export const useVisits = () => {
  const dispatch = useDispatch();
  const visits = useSelector((state: RootState) => state.visits.data);
  const isLoading = useSelector((state: RootState) => state.visits.isLoading);
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    if (visits.length === 0 && !isLoading) {
      dispatch(setVisitsLoading(true));

      api<VisitsResponse>(getVisits())
        .then((visitsResponse) => {
          dispatch(setVisits(visitsResponse.data.visits));
        })
        .catch((error) => {
          showBoundary(error);
        })
        .finally(() => {
          dispatch(setVisitsLoading(false));
        });
    }
  }, [dispatch, visits, isLoading]);

  return { visits, isLoading };
};
