import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Alert, Button, Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { Stipend } from "../../types/stipendTypes";
import { useTranslation } from "react-i18next";
import StorageManager from "../../services/storage";

interface Props {
  stipend: Stipend;
  currency: string;
  onClaim: (stipend: Stipend) => void;
  onDecline: (stipend: Stipend) => void;
}
{
  // TODO: Should be removed and use ClaimCard component instead.
}
const MyStipendCard = ({ stipend, currency, onClaim, onDecline }: Props) => {
  const style: React.CSSProperties = {
    border: "none",
    paddingLeft: 0,
    paddingRight: 0,
  };

  const { t } = useTranslation();
  const isCarer = StorageManager.getLoginInformation()?.userType === "carer";

  const getStipendTitle = (stipend: Stipend): string => {
    let total = stipend.amount ?? 0;
    let totalString = `${currency}${total.toFixed(2)}`;

    return t("claim_stipend_description", { "0": totalString });
  };

  const title = getStipendTitle(stipend);
  function onClaimPress() {
    onClaim(stipend);
  }

  function onDeclinePress() {
    onDecline(stipend);
  }

  return (
    <Alert severity="info" variant="outlined" icon={false} style={style}>
      <Grid container spacing={1}>
        <Grid item xs={1} justifyContent="center" alignItems="center">
          <AttachMoneyIcon
            sx={{
              borderRadius: "50%",
              backgroundColor: "primary.main",
              color: "white",
              width: 35,
              height: 35,
              padding: 1,
              fontSize: "50px",
            }}
          />
        </Grid>
        <Grid item xs={10}>
          <Typography
            variant="body1"
            color="primary"
            data-test-id="stipend-title"
            style={{ fontWeight: "bold" }}
          >
            {title}
          </Typography>
        </Grid>
      </Grid>

      <Stack direction="column" spacing={2} mt={2}>
        <Button
          sx={{ textTransform: "initial", fontWeight: "bold" }}
          variant="contained"
          color="primary"
          size="large"
          data-test-id="stipend-primary-button"
          onClick={onClaimPress}
        >
          {t("claim_stipend_claimButton")}
        </Button>

        <Button
          sx={{ textTransform: "initial" }}
          variant="outlined"
          color="secondary"
          size="large"
          data-test-id="stipend-secondary-button"
          onClick={onDeclinePress}
        >
          {t("claim_stipend_decline_button")}
        </Button>
      </Stack>
    </Alert>
  );
};

export default MyStipendCard;
