import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { Alert, Link, Skeleton, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useDecrypt from "../../common/useDecrypt";
import { PaymentAccountDetails, PaymentType } from "../../types/paymentTypes";

export const paymentMethodIcon = (paymentMethod: string) => {
  switch (paymentMethod) {
    case PaymentType.BankAccount:
      return <AccountBalanceIcon color="action" />;
    case PaymentType.Check:
    case "Email":
      return <AttachEmailIcon color="action" />;
    default:
      return <CreditCardIcon color="action" />;
  }
};

interface LatestPaymentProps {
  paymentAccount: PaymentAccountDetails;
  showChangeButton?: boolean;
  useTableStyle?: boolean;
}

const LatestPayment: React.FC<LatestPaymentProps> = ({
  paymentAccount,
  showChangeButton = true,
  useTableStyle = false,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let { id } = useParams<{ id: string }>();
  const path = useLocation().pathname;

  const onProceed = () => {
    if (path.startsWith("/payment/submit/receipt")) {
      id = "receipt";
    } else if (path.startsWith("/payment/submit/mileage")) {
      id = "mileage";
    }

    navigate(`/payment/submit/${id}/payment-methods`);
  };

  const isEmail = paymentAccount.target_account_type === PaymentType.Check || paymentAccount.target_account_type === "Email";
  const shouldDecrypt = paymentAccount.target_account_type !== PaymentType.BankAccount

  const { decryptedId, decryptionLoading } = isEmail
    ? {
      decryptedId: paymentAccount.target_account_identifier,
      decryptionLoading: false,
    }
    : useDecrypt(
      paymentAccount.target_account_identifier,
      [],
      !isEmail,
      shouldDecrypt
    );


  function describePaymentAccount(
    account_type: string,
    identifier: string
  ): string {
    switch (account_type) {
      case "Bank account":
      case PaymentType.BankAccount:
        return t("claimDetail_bankDescription", { "0": identifier.slice(-4) });
      case "Email":
      case PaymentType.Check:
        return t("claimDetail_checkDescription", { "0": identifier });
      case "Debit card":
      case PaymentType.DebitCard:
        return t("claimDetail_cardDescription", { "0": identifier });
      case "Virtual credit card":
      case PaymentType.VirtualCard:
        return t("claimDetail_vccDescription", { "0": identifier.slice(-4) });
      default:
        return account_type;
    }
  }

  return (
    <Alert
      icon={paymentMethodIcon(paymentAccount.target_account_type)}
      data-test-id="payment-account"
      sx={{
        border: "1px solid #0000001F",
        textAlign: "left",
        background: "inherit",
        my: "1.3rem",
        ...(useTableStyle && {
          width: "100%",
          border: "none",
          mt: "0px",
          mb: "0px",
        }),
      }}
    >
      <Typography
        data-test-id="payment-account-title"
        variant="subtitle2"
        color="text.secondary"
      >
        {t("mileageReview_PaymentMethod")}
      </Typography>

      <Typography variant="body2" data-test-id="payment-account-description">
        {decryptionLoading && !isEmail ? (
          <Skeleton variant="text" width={200}></Skeleton>
        ) : (
          describePaymentAccount(
            paymentAccount.target_account_type,
            decryptedId ?? paymentAccount.target_account_identifier
          )
        )}
      </Typography>

      {showChangeButton && (
        <Link
          onClick={onProceed}
          underline="always"
          data-test-id="update-payment-method"
          sx={{ cursor: "pointer" }}
        >
          {t("mileageReview_ChangePaymentMethod")}
        </Link>
      )}
    </Alert>
  );
};

export default LatestPayment;
