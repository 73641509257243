import { t } from "i18next";
import { getSymbol } from "../common/helpers";
import ReceiptImagePreview from "../components/ReceiptImagePreview";
import StorageManager from "../services/storage";
import { Visit, dateString } from "../types/common";
import {
  Claim,
  ClaimItem,
  MileageItem,
  ReceiptItem,
  StipendItem,
} from "../types/paymentTypes";
import {
  Action,
  DetailSection,
  SummaryPageUIProps,
  VisitDetailSection,
} from "./SummaryPageUI";

export default function getPaymentDetails(
  claim: Claim,
  visit: Visit | null,
  onApprove: (claim: Claim) => void,
  onReject: (claim: Claim) => void,
  isApproving: boolean,
  isRejecting: boolean
) {
  const isParticipantViewingCarerClaim =
    claim.claim_owner === "carer" &&
    StorageManager.getLoginInformation()?.userType === "participant";

  switch (claim.claim_type) {
    case "Mileage":
      return getMileageDetails(
        claim,
        visit,
        isParticipantViewingCarerClaim,
        isParticipantViewingCarerClaim,
        onApprove,
        onReject,
        isApproving,
        isRejecting
      );
    case "Receipt":
      return getReceiptDetails(
        claim,
        visit,
        isParticipantViewingCarerClaim,
        isParticipantViewingCarerClaim,
        onApprove,
        onReject,
        isApproving,
        isRejecting
      );
    case "Stipend":
      return getStipendDetails(
        claim,
        visit,
        isParticipantViewingCarerClaim,
        isParticipantViewingCarerClaim,
        onApprove,
        onReject,
        isApproving,
        isRejecting
      );
  }
}

function getMileageDetails(
  claim: Claim,
  visit: Visit | null,
  hidePaymentMethod: boolean,
  showApproveRejectActions: boolean,
  onApprove: (claim: Claim) => void,
  onReject: (claim: Claim) => void,
  isApproving: boolean,
  isRejecting: boolean
): SummaryPageUIProps {
  const mileageItems = claim.items as MileageItem[];
  const paymentAccountCurrency = claim.payment_details.payment_currency;
  const paymentAccountCurrencySymbol = getSymbol(paymentAccountCurrency);

  const rateCurrency = claim.original_currency ?? paymentAccountCurrency;
  const rateCurrencySymbol = getSymbol(rateCurrency);

  const detailSections: DetailSection[] =
    mileageItems.flatMap((item) => {
      return [
        {
          title:
            item.original_distance_unit === "mi" ? t("miles") : t("kilometers"),
          content: `${item.distance} ${item.original_distance_unit}`,
        },
        {
          title:
            item.original_distance_unit === "mi"
              ? t("mileageReview_RatePerMile")
              : t("mileageReview_RatePerKilometer"),
          content: `${rateCurrencySymbol}${item.rate?.toFixed(2)}`,
        },
      ];
    }) ?? [];

  const itemAmountsSum = sumAmounts(mileageItems);
  let total = itemAmountsSum;

  if (
    claim.original_currency &&
    claim.requested_currency &&
    claim.conversion_rate &&
    claim.conversion_rate > 0
  ) {
    total = sumAmountsToPay(mileageItems);
    let conversionInfo = [
      {
        title: t("claim_originalTotal"),
        content: `${getSymbol(claim.original_currency)}${itemAmountsSum}`,
      },
      {
        title: t("claim_conversionRate"),
        content: `${getSymbol(claim.original_currency)}1 = ${getSymbol(
          claim.requested_currency
        )}${claim.conversion_rate}`,
      },
    ];
    detailSections.push(...conversionInfo);
  }

  const visitSection: VisitDetailSection = {
    title: t("claimDetail_relatedVisit"),
    content: visit?.name ?? t("loading_text"),
    date: visit ? dateString(visit) : t("loading_text"),
  };

  const totalSection: DetailSection = {
    title: t("mileageReview_Total"),
    content: `${paymentAccountCurrencySymbol}${total.toFixed(2)}`,
  };

  const actions: Action[] = showApproveRejectActions
    ? [
        {
          label: t("claimDetail_rejectClaim"),
          onClick: () => onReject(claim),
          color: "secondary",
          variant: "outlined",
          loading: isRejecting,
        },
        {
          label: t("claimDetail_approveClaim"),
          onClick: () => onApprove(claim),
          color: "primary",
          variant: "contained",
          loading: isApproving,
        },
      ]
    : [];

  return {
    title: t("claimDetail_claimWithId", { "0": claim.id }),
    isLoading: visit ? false : true,
    showChangePaymentMethodButton: false,
    subtitle: "",
    detailSections: detailSections,
    visitSection: visitSection,
    notes: claim.notes,
    actions: actions,
    totalSection: totalSection,
    latestPaymentAccount: !hidePaymentMethod ? claim.payment_details : null,
  };
}

function getReceiptDetails(
  claim: Claim,
  visit: Visit | null,
  hidePaymentMethod: boolean,
  showApproveRejectActions: boolean,
  onApprove: (claim: Claim) => void,
  onReject: (claim: Claim) => void,
  isApproving: boolean,
  isRejecting: boolean
): SummaryPageUIProps {
  const receiptItems = claim.items as ReceiptItem[];
  const paymentAccountCurrency = claim.payment_details.payment_currency;
  const paymentAccountCurrencySymbol = getSymbol(paymentAccountCurrency);

  const receiptCurrency = claim.original_currency ?? paymentAccountCurrency;
  const receiptCurrencySymbol = getSymbol(receiptCurrency);

  const detailSections: DetailSection[] =
    receiptItems.map((item) => {
      return {
        title: item.category,
        content: `${receiptCurrencySymbol}${item.amount?.toFixed(2)}`,
      };
    }) ?? [];

  const itemAmountsSum = sumAmounts(receiptItems);
  let total = itemAmountsSum;

  if (
    claim.original_currency &&
    claim.requested_currency &&
    claim.conversion_rate &&
    claim.conversion_rate > 0
  ) {
    total = sumAmountsToPay(receiptItems);
    let conversionInfo: DetailSection[] = [];
    if (claim.items!.length > 1) {
      conversionInfo.push({
        title: t("claim_originalTotal"),
        content: `${getSymbol(claim.original_currency)}${itemAmountsSum.toFixed(
          2
        )}`,
      });
    }
    conversionInfo.push({
      title: t("claim_conversionRate"),
      content: `${getSymbol(claim.original_currency)}1 = ${getSymbol(
        claim.requested_currency
      )}${claim.conversion_rate}`,
    });

    detailSections.push(...conversionInfo);
  }

  const visitSection: VisitDetailSection = {
    title: t("claimDetail_relatedVisit"),
    content: visit?.name ?? t("loading_text"),
    date: visit ? dateString(visit) : t("loading_text"),
  };

  const totalSection: DetailSection = {
    title: t("mileageReview_Total"),
    content: `${paymentAccountCurrencySymbol}${total.toFixed(2)}`,
  };

  const actions: Action[] = showApproveRejectActions
    ? [
        {
          label: t("claimDetail_rejectClaim"),
          onClick: () => onReject(claim),
          color: "secondary",
          variant: "outlined",
          loading: isRejecting,
        },
        {
          label: t("claimDetail_approveClaim"),
          onClick: () => onApprove(claim),
          color: "primary",
          variant: "contained",
          loading: isApproving,
        },
      ]
    : [];

  return {
    title: t("claimDetail_claimWithId", { "0": claim.id }),
    isLoading: visit ? false : true,
    showChangePaymentMethodButton: false,
    subtitle: "",
    detailSections: detailSections,
    visitSection: visitSection,
    notes: claim.notes,
    actions: actions,
    totalSection: totalSection,
    latestPaymentAccount: !hidePaymentMethod ? claim.payment_details : null,
    topRightContent: <ReceiptImagePreview source={claim.receipt_photo_url} />,
  };
}

function sumAmountsToPay(items: ClaimItem[]): number {
  return items.reduce((total, item) => {
    return total + (item.amount_to_pay || 0);
  }, 0);
}

function sumAmounts(items: ClaimItem[]): number {
  return items.reduce((total, item) => {
    return total + (item.amount || 0);
  }, 0);
}

function getStipendDetails(
  claim: Claim,
  visit: Visit | null,
  hidePaymentMethod: boolean,
  showApproveRejectActions: boolean,
  onApprove: (claim: Claim) => void,
  onReject: (claim: Claim) => void,
  isApproving: boolean,
  isRejecting: boolean
): SummaryPageUIProps {
  const stipendItem = claim.items[0] as StipendItem;
  const hasTax = stipendItem.withholding_tax_percentage > 0;
  const detailSections: DetailSection[] = [];
  const currency = claim.payment_details.payment_currency;
  const currencySymbol = getSymbol(currency);

  const visitSection: VisitDetailSection = {
    title: t("claimDetail_relatedVisit"),
    content: visit?.name ?? t("loading_text"),
    date: visit ? dateString(visit) : t("loading_text"),
  };

  if (hasTax) {
    detailSections.push(
      {
        title: t("claim_originalTotal"),
        content: `${currencySymbol}${stipendItem.total_amount.toFixed(2)}`,
      },
      {
        title: t("claim_irsWithholding", {
          "0": `${(stipendItem.withholding_tax_percentage * 100)?.toFixed(2)}%`,
        }),
        content: `${currencySymbol}${(
          stipendItem.total_amount - stipendItem.amount
        ).toFixed(2)}`,
      }
    );
  }
  const totalSection: DetailSection = {
    title: t("mileageReview_Total"),
    content: `${currencySymbol}${(hasTax
      ? stipendItem.amount
      : stipendItem.total_amount
    ).toFixed(2)}`,
  };

  const paymentDetails =
    claim.state === "Rejected" ? null : claim.payment_details;

  const actions: Action[] = showApproveRejectActions
    ? [
        {
          label: t("claimDetail_rejectClaim"),
          onClick: () => onReject(claim),
          color: "secondary",
          variant: "outlined",
          loading: isRejecting,
        },
        {
          label: t("claimDetail_approveClaim"),
          onClick: () => onApprove(claim),
          color: "primary",
          variant: "contained",
          loading: isApproving,
        },
      ]
    : [];

  return {
    title: t("claimDetail_claimWithId", { "0": claim.id }),
    isLoading: visit ? false : true,
    showChangePaymentMethodButton: false,
    subtitle: "",
    detailSections: detailSections,
    notes: claim.state === "Rejected" ? claim.notes : null,
    visitSection: visitSection,
    actions: actions,
    totalSection: totalSection,
    latestPaymentAccount: !hidePaymentMethod ? paymentDetails : null,
  };
}
