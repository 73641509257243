import {
  BankAccountData,
  ChequePaymentData,
  ConvertCurrencyData,
  DebitCardData,
  MileageClaimData,
  VccPaymentData,
} from "../types/paymentTypes";

import { AxiosRequestConfig } from "axios";

const paymentService = {
  createPayment: (
    data: BankAccountData | ChequePaymentData | VccPaymentData | DebitCardData,
    endpoint: string
  ): AxiosRequestConfig => {
    return {
      url: endpoint,
      method: "POST",
      data: data,
    };
  },

  getVccPaymentVisualise: (): AxiosRequestConfig => {
    return {
      url: "/claimants/payments/vcc/visualise/",
      method: "GET",
    };
  },

  uploadReceiptImage: (data: UploadData): AxiosRequestConfig => {
    return {
      url: "/claimants/claims/receipt-photo",
      method: "POST",
      data: data,
    };
  },

  calculateMileageRate: (distance: number): AxiosRequestConfig => {
    return {
      url: "/claimants/claims/mileage/calculate-amount",
      method: "POST",
      data: { distance: distance },
    };
  },

  submitMileageClaim: (data: MileageClaimData): AxiosRequestConfig => {
    return {
      url: "/claimants/claims/mileage/",
      method: "POST",
      data: data,
    };
  },

  submitVehicleInfo: (data: any): AxiosRequestConfig => {
    return {
      url: "/claimants/claims/mileage/rate",
      method: "POST",
      data: data,
    };
  },
  convertCurrency: (data: ConvertCurrencyData): AxiosRequestConfig => {
    return {
      url: "/claimants/claims/convert/",
      method: "POST",
      params: data,
    };
  },
  approveClaim: (claimId: number): AxiosRequestConfig => {
    return {
      url: `/participants/carers/claims/${claimId}/approve/`,
      method: "POST",
    };
  },
  rejectClaim: (claimId: number): AxiosRequestConfig => {
    return {
      url: `/participants/carers/claims/${claimId}/reject/`,
      method: "POST",
    };
  },
};

export interface DistanceCalculationResult {
  rates: Rate[];
  distance_threshold?: number;
  total_distance: number;
  total_amount: number;
}

export interface Rate {
  distance: number;
  rate: number;
  amount: number;
}

export interface UploadData {
  extension: string;
  receipt_photo: string;
}

export default paymentService;
