import { Box, Button, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { usePageTitle } from "../common/TitleContext";
{
  // TODO: we will remove this page when we integrate with Taxbandits
}
const EmailSentPage = () => {
  const { t } = useTranslation();

  usePageTitle("Email Validation");

  const navigate = useNavigate();

  const senderEmail = "sender@example.com";
  const userEmail = "user@example.com";

  const handleResendEmail = () => {
    // Implement resend email logic
    console.log("Resend email logic goes here");
  };

  const handleUpdateEmail = () => {
    // Navigate back to update email page
    navigate(-1);
  };

  return (
    <Box sx={{ maxWidth: 500, mx: "auto", textAlign: "center", p: 2 }}>
      <Typography variant="h4" gutterBottom>
        {t("tax_validateYourEmail_header")}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t("tax_onboarding_checkEmail_description_p1", {
          "0": senderEmail,
          "1": userEmail,
        })}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t("tax_onboarding_checkEmail_description_p2")}
      </Typography>
      <Link
        component="button"
        textAlign={"center"}
        variant="body2"
        onClick={handleUpdateEmail}
        sx={{ display: "block", mt: 2 }}
      >
        {t("tax_validateYourEmail_incorrectEmail")}
      </Link>
      <Typography variant="body1" sx={{ mt: 2 }}>
        {t("tax_validateYourEmail_didntReceiveEmail")}
      </Typography>
      <Button variant="outlined" onClick={handleResendEmail} sx={{ mt: 1 }}>
        {t("tax_validateYourEmail_resend")}
      </Button>
    </Box>
  );
};

export default EmailSentPage;
