import { Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAPI } from "../../api/api";
import { getStates } from "../../api/paymentMethodsService";
import { setStates } from "../../redux/slices/paymentMethodsSlice";
import { useErrorHandler } from "../../common/hooks";
import { StatesList } from "../../types/paymentTypes";

type StateAutocompleteProps = {
  countryCode: string;
  label: string;
  selectedStateCode: string;
  handleStateChange: (e: any, value: any) => void;
  disabled: boolean;
};

const StateAutocomplete: React.FC<StateAutocompleteProps> = ({
  countryCode,
  label,
  selectedStateCode,
  handleStateChange,
  disabled,
}) => {
  const dispatch = useDispatch();
  const { handleServerError } = useErrorHandler();

  const { data, error } = useAPI<StatesList>(getStates(countryCode));

  useEffect(() => {
    dispatch(setStates(data?.values ?? []));
  }, [data]);

  useEffect(() => {
    if (!error) {
      return;
    }

    handleServerError(error);
  }, [error]);

  return (
    <Grid item>
      <Autocomplete
        data-test-id="state"
        id="state-autocomplete"
        options={data?.values ?? []}
        disabled={disabled}
        getOptionLabel={(option) => option.name}
        value={
          data?.values.find((state) => state.code === selectedStateCode) || null
        }
        onChange={handleStateChange}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </Grid>
  );
};

export default StateAutocomplete;
