import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { LoadingButton } from "@mui/lab";
import { AxiosError } from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { api } from "../api/api";
import { createCarer } from "../api/userService";
import { Carer } from "../types/common";

interface Props {
  onInvite: (carer: Carer) => void;
  onError: (error: AxiosError) => void;
}

function InviteCarerButton({ onInvite, onError }: Props) {
  const [invitingCarer, setInvitingCarer] = useState(false);
  const { t } = useTranslation();

  function inviteCarer() {
    setInvitingCarer(true);
    api<Carer>(createCarer())
      .then((response) => {
        onInvite(response.data);
      })
      .catch((error: any) => onError(error as AxiosError))
      .finally(() => {
        setInvitingCarer(false);
      });
  }

  return (
    <LoadingButton
      data-test-id="invite-button"
      size="large"
      variant="contained"
      color="primary"
      loading={invitingCarer}
      loadingPosition="start"
      onClick={inviteCarer}
      startIcon={<GroupAddIcon />}
    >
      {t("carerInvitation_inviteYourCarer")}
    </LoadingButton>
  );
}

export default InviteCarerButton;
