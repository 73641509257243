import { PaymentStatus } from "../../types/stipendTypes";

export const statusSeverity = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.Rejected:
      return "error";
    default:
      return "info";
  }
};

export function toTitleCase(str: string) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}
