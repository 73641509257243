import CancelIcon from "@mui/icons-material/Cancel";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Alert, AlertTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getSymbol, totalAmount } from "../../common/helpers";
import StorageManager from "../../services/storage";
import { formatDateOnly, formatISODate } from "../../types/common";
import { Claim, ReceiptItem } from "../../types/paymentTypes";
import { PaymentStatus } from "../../types/stipendTypes";

interface ClaimCardProps {
  claim: Claim;
  onClick: () => void; // Add an onClick prop
}

function ClaimCard({ claim, onClick }: ClaimCardProps) {
  const { t } = useTranslation();

  const statusSeverity = (
    status: PaymentStatus,
    preApproveStatus: PaymentStatus
  ) => {
    if (preApproveStatus === PaymentStatus.Pending) return "info";
    
    switch (status) {
      case PaymentStatus.Rejected:
        return "error";
      case PaymentStatus.Approved:
        return "success";
      default:
        return "info";
    }
  };

  interface ClaimDetails {
    title: string;
    subtitle: string;
  }

  function getClaimDetails(claim: Claim): ClaimDetails {
    switch (claim.claim_type) {
      case "Mileage":
      case "Receipt":
        return generalClaimDetails(claim);
      case "Stipend":
        return stipendClaimDetails(claim);
    }
  }

  // Stipend

  const stipendSubtitle = () => {
    const changeDate =
      claim.claimant_payment_status_changed_at ??
      claim.approver_payment_status_changed_at;

    const formattedDate = formatISODate(changeDate!);

    if (!isCarer && claim.claim_owner === "carer")
      return t("claimDetail_approveOrRejectThisClaim");

    if (isCarer && claim.pre_approved == PaymentStatus.Pending)
      return t("claimsList_preApproveSubtitle");

    switch (claim.state) {
      case PaymentStatus.Rejected:
        const rejectedBy = claim.claimant_payment_status_changed_at
          ? t("me")
          : t("site");
        return `${t("claim_status_rejectedBy", {
          "0": rejectedBy,
        })} ${formattedDate}`;

      case PaymentStatus.Approved:
        return `${t("claim_status_approved")}`;

      default:
        return "";
    }
  };

  function stipendClaimDetails(claim: Claim): ClaimDetails {
    const amount = claim.items[0].amount.toFixed(2);
    const currencySymbol = getSymbol(claim.payment_details.payment_currency);

    return {
      title: `${currencySymbol}${amount}`,
      subtitle: stipendSubtitle(),
    };
  }

  // Receipt
  const isCarer = StorageManager.getLoginInformation()?.userType === "carer";

  function generalClaimDetails(claim: Claim): ClaimDetails {
    let items = claim.items as ReceiptItem[];
    let total = totalAmount(items);
    const currencySymbol = getSymbol(claim.payment_details.payment_currency);

    const title: string = `${currencySymbol}${total.toFixed(2)}`;
    let subtitle: string = "";
    const dateString = formatDateOnly(claim.created_at);

    if (
      !isCarer &&
      claim.claim_owner === "carer" &&
      claim.pre_approved === PaymentStatus.Pending
    )
      return {
        title: title,
        subtitle: t("claimDetail_approveOrRejectThisClaim"),
      };

    if (isCarer && claim.pre_approved === PaymentStatus.Pending)
      return { title: title, subtitle: t("claimsList_preApproveSubtitle") };
    switch (claim.state) {
      case PaymentStatus.Incomplete:
        subtitle = t("claim_status_incomplete");
        break;
      case PaymentStatus.Approved:
        subtitle = `${t("claim_status_approved")} ${dateString}`;
        break;
      case PaymentStatus.Pending:
        subtitle = t("claim_status_pending");
        break;
      case PaymentStatus.Rejected:
        subtitle = `${t("claim_status_reject")} ${dateString}`;
        break;
    }
    return { title: title, subtitle: subtitle };
  }

  // Mileage

  const details = getClaimDetails(claim);
  return (
    <Alert
      data-test-id="payment-item"
      key={claim.id}
      onClick={onClick}
      severity={statusSeverity(claim.state, claim.pre_approved)}
      sx={{
        mb: "10px",
        cursor: "pointer",
      }}
      iconMapping={{
        error: <CancelIcon fontSize="large" />,
      }}
      action={<KeyboardArrowRightIcon sx={{ height: "100%", opacity: 0.5 }} />}
    >
      <AlertTitle data-test-id="payment-title" sx={{ fontWeight: "bold" }}>
        {details.title}
      </AlertTitle>
      {details.subtitle}
    </Alert>
  );
}

export default ClaimCard;
